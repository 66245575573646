
import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'

@Component
export default class GtiAppBar extends Vue {

  @Prop({required: false, default: null})
  title!: string
  @Prop({required: false, default: false})
  raw!: boolean
  @Prop({required: false, default: true})
  rounded!: boolean

}
