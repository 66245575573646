<script>
import {HorizontalBar} from 'vue-chartjs'

export default {
  extends: HorizontalBar,
  name: 'BarChart',
  props: {
    chartdata: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        }
      }
    }
  },
  mounted() {
    this.renderChart(this.chartdata, this.options)
  },
  watch: {
    chartdata() {
      this.renderChart(this.chartdata, this.options)
    }
  }
}
</script>
