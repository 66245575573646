
import Vue from 'vue'
import LocaleSelector from "@/components/LocaleSelector.vue";
import {axiosAuth} from '@/http-common'
import {
  getLanguageByLanguageCode,
  getUserFromLocalStorage,
  updateUiLanguage,
  updateUiSettings
} from "@/assets/auth_utils";
import {Language} from "@/model/language";
import {AxiosError, AxiosResponse} from "axios";
import store from "@/store";
import {ValidationMixin} from "@/assets/vaildation_mixin";
import {userTranslateListWithPrefix} from "@/assets/date_utils";
import {Person} from "@/model/person";

interface NewUser {
  firstname: string
  lastname: string
  name: string
  username: string
  password: string
  email: string
  metric: boolean
  timezone: string
  language: string
  setting: string
}


export default Vue.extend ({
  name: 'ProfilePane',
  mixins: [ValidationMixin],
  data() {
    return {
      //languageIntern: getLang(),

      loading: false,
      valid : false,
      userRolesIntern: 'userRoll',
      metricIntern: true,
      nameIntern: '',
      emailIntern: '',
      settingsIntern:'',
      usernameIntern: '',
      lastNameIntern: '',
      firstNameIntern: '',
      timezoneIntern: 'Local',
      timezones: [] as string[],
      languages: [] as Language[],
      languageNames: [] as string [],
      languageIntern: [] as Language [],
      languageNameIntern: ''

    }
  },
  props: ['userName'],
  created: function (){
    console.log("go c visi")
  },
  mounted: function () {
    console.log("go m visi")
    this.loadData()
    this.usernameIntern = this.userName
  },
  watch: {
    userName() {
      this.usernameIntern = this.userName
      this.loadUser()
    }
  },
  methods: {
    loadData() {
      this.loadLanguages()
    },
    loadLanguages() {
      this.loading = true
      axiosAuth.get(`/api/v1/common/languages`).then((response: AxiosResponse<Language[]>) => {
        this.languages = response.data
        let i = 0;
        this.languages.forEach(lng => {
          lng.nameLanguageToken = ""+this.$t(lng.nameLanguageToken)
          this.languageNames[i] = lng.name
          i++
        })
        this.loadTimezones();
        this.loading = false
      }).catch((error: AxiosError) => {
        console.log(error.message)
        this.loading = false
      })
    },
    loadTimezones() {
      this.loading = true
      axiosAuth.get(`/api/v1/common/timezones`).then((response: AxiosResponse<string[]>) => {
        this.timezones = response.data
        this.loadUser()
        this.loading = false
      }).catch((error: AxiosError) => {
        console.log(error.message)
        this.loading = false
      })
    },
    loadUser() {
      this.loading = true
      axiosAuth.get(`/api/v1/persons/usernames/${this.usernameIntern}`).then((response: AxiosResponse<Person>) => {
        //console.log("######## User #####: " + response.data.username + "  Metric: " + response.data.metric + " Lang: " + response.data.language.id+" TimeZone: "+response.data.timezone)
        this.languageNameIntern =getLanguageByLanguageCode(response.data.language,this.languages).name
        this.languageIntern[0] = getLanguageByLanguageCode(response.data.language,this.languages)
        this.timezoneIntern = response.data.timezone
        this.metricIntern = response.data.metric
        this.firstNameIntern = (response.data.firstName != null) ? response.data.firstName : ''
        this.lastNameIntern = (response.data.lastName != null) ? response.data.lastName : ''
        this.emailIntern = response.data.email
        this.nameIntern = response.data.name
        this.settingsIntern = response.data.setting
        this.userRolesIntern = response.data.roles


        this.loading = false
        this.userRolesIntern = userTranslateListWithPrefix(this.userRolesIntern,{prefix: 'esab.general.personnel.roles.',convertToFirstLowercase: true})
      }).catch((error: AxiosError) => {
        console.log(error.message)
        this.loading = false
      })
    },
    saveData() {
      console.log ("save user modfications")
      const newUser: NewUser = {
        username: this.usernameIntern,
        name: (this.firstNameIntern+' '+this.lastNameIntern).trimStart().trimEnd(),
        firstname: this.firstNameIntern,
        lastname: this.lastNameIntern,
        password: '',
        metric: this.metricIntern,
        language: this.languageIntern[0].code,
        timezone: this.timezoneIntern,
        setting: this.settingsIntern,
        email: this.emailIntern,
      }

      if (newUser.language.length < 1)
        newUser.language = 'Local'

      axiosAuth.put(`/api/v1/persons/usernames/${this.usernameIntern}/update`, newUser).then((response: AxiosResponse<boolean>) => {
        console.log("save user profile: "+response.data)
        //
        // axiosAuth.get(`/api/v1/settings`).then((response) => {
        //   store.state.settings = response.data
        //   store.state.moment = require('moment-timezone')
        //
        //   let lng = store.state.settings.userLanguage
        //   if (lng == "en")
        //     lng = store.state.moment.locale("en-gb");
        //   else
        //     lng = store.state.moment.locale(store.state.settings.userLanguage);
        //
        //   updateUiLanguage(store.state.settings.userLanguage, true)
        // }).catch((error) => {
        //   console.log("Setting:" + error.message)
        // })
        window.location.reload()
        this.$emit('update:close', true)
      }).catch((error: AxiosError) => {
        console.log(error.message)
      })
    },
    close() {
      // this.saveData()
      this.$emit('update:close', true)
    }
  }
})
