import {PdmData} from "@/model/pdmdata";
import {ReportResultPdmData} from "@/model/reportresultpdmdata";
import {ReportResultEventData} from "@/model/reportresulteventdata";
import {DataTableHeader} from "vuetify";
import {userTranslate} from "@/assets/date_utils";

export interface EventMachineTime {
    machineName: string
    event: string
    length: number
}

export interface PdmLens {
    machineName: string
    messLen: number
    rapidLen: number
    processLen1: number
    processLen2: number
    processLen3: number
    processLen4: number
}

export interface PdmTimes {
    machineName: string
    messLen: number
    messTime: number
    rapidTime: number
    unknownTime: number
    errorTime: number
    processTime1: number
    processTime2: number
    processTime3: number
    processTime4: number
}

export interface StackedDataSet {
    label: string
    backgroundColor: string
    data: number[]
}

export interface DataSet {
    backgroundColor: string[]
    data: number[]
}

export interface ChartParam {
    overviewStackbar: boolean
    overviewPie: boolean
    singleMachinePie?: boolean
}

const ChartStyle = {Any: 'Any', BarChart: 'BarChart', StackbarChart: 'StackbarChart', PieChart: 'PieChart'}


function getEventHeaders(): DataTableHeader[] {
    return [
        {text: userTranslate('esab.cutting.machines.machine'), value: 'machine'},
        {text: 'esab.cutting.events.event', value: 'event'},
        {text: 'esab.cutting.events.eventLen', value: 'length'}
    ] as DataTableHeader[]
}

function getPdmLenHeaders(): DataTableHeader[] {
    return [
        {text: userTranslate('esab.cutting.machines.machine'), value: 'machine'},
        {text: 'esab.cutting.pdmData.process1Len', value: 'processLen1'},
        {text: 'esab.cutting.pdmData.process2Len', value: 'processLen2'},
        {text: 'esab.cutting.pdmData.process3Len', value: 'processLen3'},
        {text: 'esab.cutting.pdmData.process4Len', value: 'processLen4'},
        {text: 'esab.cutting.pdmData.messLen', value: 'messLen'},
        {text: 'esab.cutting.pdmData.rapidLen', value: 'rapidLen'},
    ] as DataTableHeader[]
}

function getPdmTimeHeaders(): DataTableHeader[] {
    return [
        {text: userTranslate('esab.cutting.machines.machine'), value: 'machine'},
        {text: 'esab.cutting.pdmData.process1Time', value: 'processTime1'},
        {text: 'esab.cutting.pdmData.process2Time', value: 'processTime2'},
        {text: 'esab.cutting.pdmData.process3Time', value: 'processTime3'},
        {text: 'esab.cutting.pdmData.process4Time', value: 'processTime4'},
        {text: 'esab.cutting.pdmData.messTime', value: 'messTime'},
        {text: 'esab.cutting.pdmData.rapidTime', value: 'rapidTime'},
        {text: 'esab.cutting.pdmData.errorTime', value: 'errorTime'},
        {text: 'esab.cutting.pdmData.unknownTime', value: 'unknownTime'},
    ] as DataTableHeader[]
}


export interface ChartView {
    chartDataSet: ChartData | StackedChartData
    type: string
    title: string
    param?: any
    name: string
    tableData?: ReportResultEventData[] | ReportResultPdmData[]
    tableHeader?: DataTableHeader[]
    tableHeaderTranslate?: boolean
}

interface StackedChartData {
    labels: string[]
    datasets: StackedDataSet[]
}

interface ChartData {
    labels: string[]
    datasets: DataSet[]
}

function convertDatasetToPdm(dataset: any[]): ReportResultPdmData[] {
    const res: ReportResultPdmData[] = []

    for (let i = 0; i < dataset.length; i++) {
        const pd = dataset[i] as PdmData
        res.push(
            {
                machine: pd.machine,
                processLen1: pd.processLen1,
                processLen2: pd.processLen2,
                processLen3: pd.processLen3,
                processLen4: pd.processLen4,
                messLen: pd.messLen,
                rapidLen: pd.rapidLen,
                processTime1: pd.processTime1,
                processTime2: pd.processTime2,
                processTime3: pd.processTime3,
                processTime4: pd.processTime4,
                messTime: pd.messTime,
                rapidTime: pd.rapidTime,
                errorTime: pd.errorTime,
                unknownTime: pd.unknownTime
            }
        )
    }
    return res
}


function convertDatasetToEvent(dataset: any[], filterMachine: string): any[] {
    const res: ReportResultEventData[] = []

    for (let i = 0; i < dataset.length; i++) {
        const pd = dataset[i] as EventMachineTime
        if (filterMachine.length < 1 || filterMachine === pd.machineName) {
            res.push(
                {
                    machine: pd.machineName,
                    event: pd.event,
                    length: pd.length
                }
            )
        }
    }
    return res
}

function convertOverviewDatasetToEvent(stackedbarchart: StackedChartData): any[] {
    const res: any[] = []

    for (let j = 0; j < stackedbarchart.labels.length; j++) {
        const machine = stackedbarchart.labels[j]
        const eventvals: number [] = [];
        for (let i = 0; i < stackedbarchart.datasets.length; i++) {
            const pd = stackedbarchart.datasets[i] as StackedDataSet
            eventvals.push(pd.data[j])
        }
        res.push({
            machine: machine,
            event0: eventvals[0],
            event1: eventvals[1],
            event2: eventvals[2],
            event3: eventvals[3],
            event4: eventvals[4],
            event5: eventvals[5],
            event6: eventvals[6],
            event7: eventvals[7],
            event8: eventvals[8],
            event9: eventvals[9],
            event10: eventvals[10],
            event11: eventvals[11],
            event12: eventvals[12],
            event13: eventvals[13],
            event14: eventvals[14],
            event15: eventvals[15],
        })
    }
    return res
}

function convertSummaryDatasetToEvent(chartData: ChartData): any[] {
    const res: any[] = []

    for (let j = 0; j < chartData.labels.length; j++) {
        const event = chartData.labels[j]
        const eventvals: number [] = [];
        for (let i = 0; i < chartData.datasets.length; i++) {
            const pd = chartData.datasets[i] as DataSet
            eventvals.push(pd.data[j])
        }
        res.push({
            event: event,
            event0: eventvals[0],
            event1: eventvals[1],
            event2: eventvals[2],
            event3: eventvals[3],
            event4: eventvals[4],
            event5: eventvals[5],
            event6: eventvals[6],
            event7: eventvals[7],
            event8: eventvals[8],
            event9: eventvals[9],
            event10: eventvals[10],
            event11: eventvals[11],
            event12: eventvals[12],
            event13: eventvals[13],
            event14: eventvals[14],
            event15: eventvals[15],
        })
    }
    return res
}

export function doSumOfNumbers(numbers: number[]): number {
    let sum = 0
    numbers.forEach(v => {
        sum += v
    })
    return sum
}

export function transformEventData(records: EventMachineTime[], param: ChartParam): ChartView[] {
    const machines = [...new Set(records.map(r => r.machineName))]
    const events = [...new Set(records.map(r => r.event))]
    const datasets: StackedDataSet[] = []
    const sum: number[] = []

    const eventHeaders: DataTableHeader[] = []
    eventHeaders.push({text: '', value: 'machine'})
    let i = 0
    events.forEach(e => {
        eventHeaders.push({text: e, value: 'event' + i})
        i++
    })

    let col = 0
    events.forEach(evt => {
        const rec = records.filter(r => r.event === evt)

        let acc = 0

        rec.forEach(r => acc += r.length)
        sum.push(acc)

        const data = machines.map(m => {
            const c = rec.find(r => r.machineName === m)
            return c ? c.length : 0
        })

        datasets.push({
            label: evt,
            data: data,
            backgroundColor: colors[col++]
        })
    })

    const barchart = {
        labels: machines,
        datasets: datasets
    }

    const cm = []
    for (let i = 0; i < 48; i++) {
        cm.push(colors[i])
    }

    const piechart = {
        labels: events,
        datasets: [{
            data: sum,
            backgroundColor: cm
        }]
    }

    const tmpCharts: ChartView[] = []

    if (param.overviewStackbar) {
        const dt: ReportResultPdmData[] = convertOverviewDatasetToEvent(barchart)
        tmpCharts.push({
            chartDataSet: barchart,
            type: ChartStyle.StackbarChart,
            title: 'esab.cutting.reports.overview',
            name: 'EventSummaryStackbarChart',
            tableData: dt,
            tableHeader: eventHeaders,
            tableHeaderTranslate: false
        })
    }

    if (param.overviewPie) {
        const dt: ReportResultPdmData[] = convertSummaryDatasetToEvent(piechart)
        tmpCharts.push({
            chartDataSet: piechart,
            type: ChartStyle.PieChart,
            title: 'esab.cutting.reports.summary',
            name: 'EventSummaryPieChart',
            tableData: dt,
            tableHeader: [{text: 'esab.cutting.events.event', value: 'event'}, {text: 'esab.cutting.events.eventLen', value: 'event0'}],
            tableHeaderTranslate: true
        })
    }
    if (param.singleMachinePie) {
        let idx = 0
        machines.forEach(m => {
            const machineSum: number[] = []
            datasets.forEach(ds => {
                machineSum.push(ds.data[idx])
            })

            const cm = []
            for (let i = 0; i < 48; i++) {
                cm.push(colors[i])
            }
            const dt: ReportResultPdmData[] = convertDatasetToEvent(records, m)
            const machinePieChart = {
                labels: events,
                datasets: [{
                    data: machineSum,
                    backgroundColor: cm
                }]
            }
            tmpCharts.push({
                chartDataSet: machinePieChart,
                type: ChartStyle.PieChart,
                title: m,
                param: {name: m},
                name: 'EventSingleMachinePieChart',
                tableData: dt,
                tableHeader: getEventHeaders(),
                tableHeaderTranslate: true
            })
            idx++
        })
    }
    return tmpCharts
}

export function transformPdmDataLens(records: PdmLens[], param: ChartParam): ChartView[] {

    const machines = [...new Set(records.map(r => r.machineName))]
    const proLens = [userTranslate('esab.cutting.pdmData.process1Len'),userTranslate('esab.cutting.pdmData.process2Len'),userTranslate('esab.cutting.pdmData.process3Len'),userTranslate('esab.cutting.pdmData.process4Len'),userTranslate('esab.cutting.pdmData.messLen'),userTranslate('esab.cutting.pdmData.rapidLen')]

    const datasets: StackedDataSet[] = []

    const process1Len: number[] = []
    const process2Len: number[] = []
    const process3Len: number[] = []
    const process4Len: number[] = []
    const rapidLen: number[] = []
    const messLen: number[] = []
    const sumLen: number[] = []

    records.forEach(ds => {
        if (ds.processLen1)
            process1Len.push(ds.processLen1)
        else
            process1Len.push(0)

        if (ds.processLen2)
            process2Len.push(ds.processLen2)
        else
            process2Len.push(0)

        if (ds.processLen3)
            process3Len.push(ds.processLen3)
        else
            process3Len.push(0)

        if (ds.processLen4)
            process4Len.push(ds.processLen4)
        else
            process4Len.push(0)

        if (ds.messLen)
            messLen.push(ds.messLen)
        else
            messLen.push(0)

        if (ds.rapidLen)
            rapidLen.push(ds.rapidLen)
        else
            rapidLen.push(0)
    })

    sumLen.push(doSumOfNumbers(process1Len))
    sumLen.push(doSumOfNumbers(process2Len))
    sumLen.push(doSumOfNumbers(process3Len))
    sumLen.push(doSumOfNumbers(process4Len))
    sumLen.push(doSumOfNumbers(messLen))
    sumLen.push(doSumOfNumbers(rapidLen))

    let col = 0
    datasets.push({
        label: userTranslate('esab.cutting.pdmData.process1Len'),
        data: process1Len,
        backgroundColor: colors[col++]
    })
    datasets.push({
        label: userTranslate('esab.cutting.pdmData.process2Len'),
        data: process2Len,
        backgroundColor: colors[col++]
    })
    datasets.push({
        label: userTranslate('esab.cutting.pdmData.process3Len'),
        data: process3Len,
        backgroundColor: colors[col++]
    })
    datasets.push({
        label: userTranslate('esab.cutting.pdmData.process4Len'),
        data: process4Len,
        backgroundColor: colors[col++]
    })
    datasets.push({
        label: userTranslate('esab.cutting.pdmData.messLen'),
        data: messLen,
        backgroundColor: colors[col++]
    })
    datasets.push({
        label: userTranslate('esab.cutting.pdmData.rapidLen'),
        data: rapidLen,
        backgroundColor: colors[col++]
    })

    const barchartSumLen = {
        labels: machines,
        datasets: datasets
    }

    const cm = []
    for (let i = 0; i < 8; i++) {
        cm.push(colors[i])
    }

    const piechartSumLen = {
        labels: proLens,
        datasets: [{
            data: sumLen,
            backgroundColor: cm
        }]
    }

    const tmpCharts: ChartView[] = []

    if (param.overviewStackbar) {
        const dt: ReportResultPdmData[] = convertDatasetToPdm(records)
        tmpCharts.push({
            chartDataSet: barchartSumLen,
            type: ChartStyle.StackbarChart,
            title: 'esab.cutting.reports.overview',
            name: 'PdmSummaryLensStackbarChart',
            tableData: dt,
            tableHeader: getPdmLenHeaders(),
            tableHeaderTranslate: true
        })
    }

    if (param.overviewPie) {
        const dt: ReportResultPdmData[] = [{
            machine: ' ',
            processLen1: piechartSumLen.datasets[0].data[0],
            processLen2: piechartSumLen.datasets[0].data[1],
            processLen3: piechartSumLen.datasets[0].data[2],
            processLen4: piechartSumLen.datasets[0].data[3],
            messLen: piechartSumLen.datasets[0].data[4],
            rapidLen: piechartSumLen.datasets[0].data[5],
        }]
        tmpCharts.push({
            chartDataSet: piechartSumLen,
            type: ChartStyle.PieChart,
            title: 'esab.cutting.reports.summary',
            name: 'PdmSummaryLensPieChart',
            tableData: dt,
            tableHeader: getPdmLenHeaders(),
            tableHeaderTranslate: true
        })
    }

    return tmpCharts
}

export function transformPdmDataTimes(records: PdmTimes[], param: ChartParam): ChartView[] {

//  const represpdm = convertDatasetToPdm(records)

    const machines = [...new Set(records.map(r => r.machineName))]
    const proTimes = [userTranslate('esab.cutting.pdmData.process1Time'), userTranslate('esab.cutting.pdmData.process2Time'), userTranslate('esab.cutting.pdmData.process3Time'), userTranslate('esab.cutting.pdmData.process4Time'), userTranslate('esab.cutting.pdmData.messTime'), userTranslate('esab.cutting.pdmData.errorTime'),   userTranslate('esab.cutting.pdmData.unknownTime')]

    const datasets: StackedDataSet[] = []

    const process1Time: number[] = []
    const process2Time: number[] = []
    const process3Time: number[] = []
    const process4Time: number[] = []
    const rapidTime: number[] = []
    const messTime: number[] = []
    const errorTime: number[] = []
    const unknownTime: number[] = []
    const sumTime: number[] = []


    records.forEach(ds => {
        if (ds.processTime1)
            process1Time.push(ds.processTime1)
        else
            process1Time.push(0)

        if (ds.processTime2)
            process2Time.push(ds.processTime2)
        else
            process2Time.push(0)

        if (ds.processTime3)
            process3Time.push(ds.processTime3)
        else
            process3Time.push(0)

        if (ds.processTime4)
            process4Time.push(ds.processTime4)
        else
            process4Time.push(0)

        if (ds.messTime)
            messTime.push(ds.messTime)
        else
            messTime.push(0)

        if (ds.rapidTime)
            rapidTime.push(ds.rapidTime)
        else
            rapidTime.push(0)

        if (ds.errorTime)
            errorTime.push(ds.errorTime)
        else
            errorTime.push(0)

        if (ds.unknownTime)
            unknownTime.push(ds.unknownTime)
        else
            unknownTime.push(0)
    })

    sumTime.push(doSumOfNumbers(process1Time))
    sumTime.push(doSumOfNumbers(process2Time))
    sumTime.push(doSumOfNumbers(process3Time))
    sumTime.push(doSumOfNumbers(process4Time))
    sumTime.push(doSumOfNumbers(messTime))
    sumTime.push(doSumOfNumbers(rapidTime))
    sumTime.push(doSumOfNumbers(errorTime))
    sumTime.push(doSumOfNumbers(unknownTime))

    let col = 0
    datasets.push({
        label: userTranslate('esab.cutting.pdmData.process1Time'),
        data: process1Time,
        backgroundColor: colors[col++]
    })
    datasets.push({
        label: userTranslate('esab.cutting.pdmData.process2Time'),
        data: process2Time,
        backgroundColor: colors[col++]
    })
    datasets.push({
        label: userTranslate('esab.cutting.pdmData.process3Time'),
        data: process3Time,
        backgroundColor: colors[col++]
    })
    datasets.push({
        label: userTranslate('esab.cutting.pdmData.process4Time'),
        data: process4Time,
        backgroundColor: colors[col++]
    })
    datasets.push({
        label: userTranslate('esab.cutting.pdmData.messTime'),
        data: messTime,
        backgroundColor: colors[col++]
    })
    datasets.push({
        label: userTranslate('esab.cutting.pdmData.rapidTime'),
        data: rapidTime,
        backgroundColor: colors[col++]
    })
    datasets.push({
        label: userTranslate('esab.cutting.pdmData.errorTime'),
        data: errorTime,
        backgroundColor: colors[col++]
    })
    datasets.push({
        label: userTranslate('esab.cutting.pdmData.unknownTime'),
        data: unknownTime,
        backgroundColor: colors[col++]
    })


    const barchartSumTime = {
        labels: machines,
        datasets: datasets
    }

    const cm = []
    for (let i = 0; i < 8; i++) {
        cm.push(colors[i])
    }

    const piechartSumTime = {
        labels: proTimes,
        datasets: [{
            data: sumTime,
            backgroundColor: cm
        }]
    }

    const tmpCharts: ChartView[] = []

    if (param.overviewStackbar) {
        const dt: ReportResultPdmData[] = convertDatasetToPdm(records)
        tmpCharts.push({
            chartDataSet: barchartSumTime,
            type: ChartStyle.StackbarChart,
            title: 'esab.cutting.reports.overview',
            name: 'PdmSummaryTimesStackbarChart',
            tableData: dt,
            tableHeader: getPdmTimeHeaders(),
            tableHeaderTranslate: true
        })
    }

    if (param.overviewPie) {
        const dt: ReportResultPdmData[] = [{
            machine: ' ',
            processTime1: piechartSumTime.datasets[0].data[0],
            processTime2: piechartSumTime.datasets[0].data[1],
            processTime3: piechartSumTime.datasets[0].data[2],
            processTime4: piechartSumTime.datasets[0].data[3],
            messTime: piechartSumTime.datasets[0].data[4],
            rapidTime: piechartSumTime.datasets[0].data[5],
            errorTime: piechartSumTime.datasets[0].data[6],
            unknownTime: piechartSumTime.datasets[0].data[7],
        }]

        tmpCharts.push({
            chartDataSet: piechartSumTime,
            type: ChartStyle.PieChart,
            title: 'esab.cutting.reports.summaryPie',
            name: 'PdmSummaryTimesPieChart',
            tableData: dt,
            tableHeader: getPdmTimeHeaders(),
            tableHeaderTranslate: true
        })
    }

    return tmpCharts
}

// const colors1 = ['#3090F0', '#EC6464', '#98DF58', '#F9DD51',
//     '#24DCD4', '#EC64A5', '#685CB0', '#FF7D42',
//     '#336190', '#AA514D', '#7FB053', '#BBA85B',
//     '#247981', '#963970', '#4B56A8', '#9A593D']

const colors = [
                '#3366CC', '#DC3912', '#FF9900', '#109618', '#990099', '#3B3EAC', '#0099C6', '#DD4477', '#66AA00', '#B82E2E', '#316395', '#994499', '#22AA99', '#AAAA11', '#6633CC', '#E67300', '#8B0707', '#329262', '#5574A6', '#3B3EAC'
                ,'#3366CC', '#DC3912', '#FF9900', '#109618', '#990099', '#3B3EAC', '#0099C6', '#DD4477', '#66AA00', '#B82E2E', '#316395', '#994499', '#22AA99', '#AAAA11', '#6633CC', '#E67300', '#8B0707', '#329262', '#5574A6', '#3B3EAC'
                ,'#3366CC', '#DC3912', '#FF9900', '#109618', '#990099', '#3B3EAC', '#0099C6', '#DD4477', '#66AA00', '#B82E2E', '#316395', '#994499', '#22AA99', '#AAAA11', '#6633CC', '#E67300', '#8B0707', '#329262', '#5574A6', '#3B3EAC'
                ,'#3366CC', '#DC3912', '#FF9900', '#109618', '#990099', '#3B3EAC', '#0099C6', '#DD4477', '#66AA00', '#B82E2E', '#316395', '#994499', '#22AA99', '#AAAA11', '#6633CC', '#E67300', '#8B0707', '#329262', '#5574A6', '#3B3EAC'
                ,'#3366CC', '#DC3912', '#FF9900', '#109618', '#990099', '#3B3EAC', '#0099C6', '#DD4477', '#66AA00', '#B82E2E', '#316395', '#994499', '#22AA99', '#AAAA11', '#6633CC', '#E67300', '#8B0707', '#329262', '#5574A6', '#3B3EAC'
]
