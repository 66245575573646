<template>
  <v-container>
    <v-layout wrap justify-space-around>
      <v-flex lg5 style="height: 500px">
        <v-list dense>
          <v-subheader>Uploads</v-subheader>
          <v-list-item link v-for="(file, i) in files" :key="i">
            <v-list-item-content>
              <v-list-item-title>
                {{ file.name }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ file.size }} kb</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <v-icon>mdi-upload</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn @click="paintPreview(file)" icon>
                <v-icon>mdi-brush</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn @click="singlePreview(file)" icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn @click="addPreview(file)" icon>
                <v-icon>mdi-pencil-plus</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn @click="removeFile(file)" icon>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-btn :disabled="uploadDisabled" @click="upload" small>
          <v-icon left>mdi-upload</v-icon>
          Upload All
        </v-btn>
      </v-flex>
      <v-flex lg4 pa-4>
        <div v-cloak @drop.prevent="addFile" @dragover.prevent class="upload-box pa-3">
          <h3>Drag Files to Upload here</h3>
        </div>
      </v-flex>
      <v-flex lg5 pa-4 class="ligh-border">
        <div id="images" style="height: 300px;">
          <img id="image"/>
        </div>
      </v-flex>
      <v-flex lg5 pa-4 class="ligh-border">
        <canvas id="canvas" width="300" height="300"/>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {axiosAuth} from '@/http-common'
import {AxiosResponse} from 'axios'
import {Machine} from '@/model/machine'

export default {
  name: 'upload',
  mounted() {
    this.canvas = document.getElementById('canvas')
    this.ctx = this.canvas.getContext('2d')
  },
  data() {
    return {
      ctx: null,
      canvas: null,
      url: null,
      files: []
    }
  },
  computed: {
    uploadDisabled() {
      return this.files.length === 0
    }
  },
  methods: {
    addPreview(file) {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function () {
        let img = document.createElement('img')
        img.width = 200
        img.src = reader.result
        document.getElementById('images').appendChild(img)
      }
    },
    singlePreview(file) {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function () {
        let image = document.getElementById('image')
        image.src = reader.result
        image.width = 200
        image.height = 200
        image.onload = function () {
          URL.revokeObjectURL(image.src) // free memory
        }
      }
    },
    paintPreview(file) {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      let ctx = this.ctx
      reader.onloadend = function () {
        let img = new Image()
        img.src = reader.result
        img.onload = () => {
          ctx.drawImage(img, 0, 0)
        }
      }
    },
    addFile(event) {
      let droppedFiles = event.dataTransfer.files
      if (!droppedFiles)
        return;
      ([...droppedFiles]).forEach(f => {
        this.files.push(f)
      })
    },
    removeFile(file) {
      this.files = this.files.filter(f => {
        return f != file
      })
    },
    upload() {

      let bodyFormData = new FormData()

      this.files.forEach((f, x) => {
        bodyFormData.append('image' + (x + 1), f)
      })

      axiosAuth.post(`/api/v1/machinegroupimage`, bodyFormData, {
        headers: {'Content-Type': 'multipart/form-data'}
      }).then(response => {
        console.log(response)
      }).catch(error => {
        console.log(error)
      })

      // fetch('https://httpbin.org/post', {
      //   method: 'POST',
      //   body:         bodyFormData.append('image' + (x + 1), f)
      //
      // })
      //     .then(res => res.json())
      //     .then(res => {
      //       console.log('done uploading', res)
      //     })
      //     .catch(e => {
      //       console.error(JSON.stringify(e.message))
      //     })
    }
  }
}
</script>

<style scoped>
.upload-box {
  border-radius: 5px;
  border: 3px dashed #888;
  background-color: #eee;
  text-align: center;
}

.ligh-border {
  border: 1px dotted #ccc;
}
</style>
