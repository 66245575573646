
import Vue from 'vue'
import {axiosAuth} from '@/http-common'
import {AxiosError, AxiosResponse} from "axios";

import {ValidationMixin} from "@/assets/vaildation_mixin";
import {Person} from "@/model/person";


export default Vue.extend({
  name: 'ChangePasswordPane',
  mixins: [ValidationMixin],
  data() {
    return {
      //languageIntern: getLang(),
      valid: false,
      showOldPwd: false,
      showNewPwd1: false,
      showNewPwd2: false,
      showAlert: false,
      errorMessage: {},
      loading: false,
      passwordOld: '',
      passwordNew1: '',
      passwordNew2: '',
      usernameIntern: ''
    }
  },
  props: ['userName'],
  created: function () {
    console.log("go c visi")
  },
  mounted: function () {
    console.log("go m visi")
    this.showAlert=false
    this.errorMessage=''
    this.usernameIntern = this.userName
  },
  watch: {
    userName() {
      this.usernameIntern = this.userName
      this.loadUser()
    }
  },
  methods: {
    loadUser() {
      this.loading = true
      axiosAuth.get(`/api/v1/persons/usernames/${this.usernameIntern}`).then((response: AxiosResponse<Person>) => {
        this.loading = false
      }).catch((error: AxiosError) => {
        console.log(error.message)
        this.loading = false
      })
    },
    saveData() {
      let reqBody = {
        oldPassword: this.passwordOld,
        newPassword: this.passwordNew2
      }
      if (this.passwordNew1 != this.passwordNew2){
        this.errorMessage="esab.general.displayMessages.msgPasswordsNotEqual"
        this.showAlert=true;
      }
      else {
        axiosAuth.post(`/api/v1/persons/usernames/${this.usernameIntern}/changepassword`, reqBody).then((response: AxiosResponse<boolean>) => {
          if (!response.data) {
            this.showPasswordChangeFailed()
          } else {
            this.showPasswordChangeSuccessful()
            this.close()
          }
        }).catch((error: AxiosError) => {
          console.log(error)
        })
      }
    },
    showPasswordChangeFailed() {
      this.errorMessage = this.$t('esab.cutting.passwords.passwordChangeFailed')
      this.showAlert = true
    },
    showPasswordChangeSuccessful() {
      this.$emit('update:message', this.$t('esab.cutting.passwords.passwordChangeSuccessful').toString())
      this.showAlert=false
      this.errorMessage=''
    },
    close() {
      this.$emit('update:close', true)
    }
  }
})
