
import Vue from "vue";
import {axiosAuth} from '@/http-common'
import {
  getUserFormToken, isAdmin, isUser,
  storeUserInLocalStorage
} from "@/assets/auth_utils";
import axios, {AxiosError, AxiosResponse} from "axios";
import {ValidationMixin} from "@/assets/vaildation_mixin";
import {LocaleMessageObject} from "vue-i18n";

interface JwtTokenResponse {
  bearerToken: string
}

export default Vue.extend({
  name: 'LoginPane',
  mixins: [ValidationMixin],
  data() {
    return {
      valid: false,
      showPwd: false,
      username: '',
      password: '',
      showAlert: false,
      errorMessage: {}
    }
  },
  methods: {
    login() {
      let reqBody = {
        username: this.username,
        password: this.password
      }
      console.log("Login: "+this.username)
      let languageId = navigator.language
      languageId = languageId.substring(languageId.length-2,languageId.length).toLowerCase()
      let urlLogin = `/login/user?browserLng=`+languageId
      let errorMsg = ''
      axiosAuth.post(urlLogin, reqBody).then((response: AxiosResponse<JwtTokenResponse>) => {
        console.log('Login successful')
        const user = getUserFormToken(response.data.bearerToken)
        storeUserInLocalStorage(user)
        if (!isUser() && !isAdmin())
        {
            console.log('Login denied: CutCloud-Gateway-User-Role')
            user.token = ''
            storeUserInLocalStorage(user)
            errorMsg = 'Login denied:no CutCloud-Gateway-User-Role'
            throw new Error('Login denied:no CutCloud-Gateway-User-Role')
        }
        this.$emit('update:login', user)
      }).catch((error: AxiosError) => {
        if (!error.response)
          this.showErrorMessage(this.$t('esab.general.displayMessages.errNetworkError')+": "+errorMsg)
        else if (error.response?.status === 403)
          this.showErrorMessage(this.$t('esab.general.displayMessages.errInternalError')+": "+errorMsg)
        else if (error.response?.status === 404)
          this.showErrorMessage(this.$t('esab.general.displayMessages.msgInvalidCredentials')+": "+errorMsg)
      })
    },
    validate() {
      return (this.$refs.loginForm as any).validate()
    },
    showErrorMessage(message: any) {
      this.errorMessage = message
      this.showAlert = true
    }
  },
  watch: {
    username() {
      this.showAlert = false
      this.valid = this.validate()
    },
    password() {
      this.showAlert = false
      this.valid = this.validate()
    }
  }
})
