
import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import InfoLine from '@/components/InfoLine.vue'
import {MachineStatesMixin} from '@/assets/machinestates_mixin'
import {axiosAuth} from '@/http-common'
import {AxiosError, AxiosResponse} from 'axios'
import ImageDrag from '@/components/ImageDrag.vue'
import {isAdmin} from '@/assets/auth_utils'
import {userFormatDate, userFormatTimeBySeconds} from '@/assets/date_utils'
import store from '@/store'
import {CurrentEvent} from '@/model/currentevent'

interface MachineRecord {
  id: number
  machine: string
  image: string
  icon: string
}

@Component({
  components: {ImageDrag, InfoLine},
  filters: {
    formatDatetime(val: string) {
      return userFormatDate(val)
    },
    formatTime(val: string) {
      let valN = parseInt(val)
      return userFormatTimeBySeconds(valN)
    }
  }
})
export default class MachineInfoPane extends mixins(MachineStatesMixin) {

  @Prop({default: null})
  currentEvent!: CurrentEvent

  isAdmin = false
  iconSource = ''
  imageSource = ''

  toggleDetail = false

  mounted(): void {
    store.commit('setInProcessState', true)

    console.log("start spinner: "+this.currentEvent.machine?.name)
    this.isAdmin = isAdmin()
    console.log('isAdmin: ' + this.isAdmin)
    store.commit('setInProcessState', true)

  }

  @Watch('currentEvent')
  onCurrentEventChanged(): void {
    store.commit('setInProcessState', false)
    console.log("end spinner: "+this.currentEvent.machine?.name)
    //console.log('changed current event')
    this.updateImageSources()
  }

  doToggleDetail() {
    this.toggleDetail = !this.toggleDetail
  }

  uploadImage(image: File, type: string): void {
    let bodyFormData = new FormData()
    bodyFormData.append(this.currentEvent.machine.name, image)
    axiosAuth.post(`/api/v1/machines/${type}`, bodyFormData, {
      headers: {'Content-Type': 'multipart/form-data'}
    }).then((response: AxiosResponse) => {
      console.log(response.status)
    }).catch((error: AxiosError) => {
      console.log(error)
    })
  }

  updateImage(file: File, type: string): void {
    console.log('uploading image')
    this.uploadImage(file, type)
  }

  updateImageSources(): void {
    if (this.currentEvent.machine?.icon)
      this.iconSource = Vue.prototype.$backendUrl + '/images/machines/' + this.currentEvent.machine.icon
    else
      this.iconSource = '/img/machineicon_placeholder.png'

    if (this.currentEvent.machine?.image)
      this.imageSource = Vue.prototype.$backendUrl + '/images/machines/' + this.currentEvent.machine.image
    else
      this.imageSource = '/img/machineimage_placeholder.png'
  }

  statusColor(): string {
    return this.currentEvent.state.colorCode
  }

  progress(): number {
    return 100 - (100 / this.currentEvent.estimatedTime * this.currentEvent.remainingTime)
  }

}
