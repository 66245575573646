
import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'
import {parse} from 'json2csv'
import {saveAs} from 'file-saver'

interface Header {
  raw: string
  text: string
}

interface HeaderConfig {
  header: Header
  active: boolean
  selectable: boolean
}

@Component({
  components: {}
})
export default class GtiDataTable extends Vue {

  @Prop({required: false, default: null})
  title!: string
  @Prop({default: ''})
  itemKey!: string
  @Prop({default: false})
  loading!: boolean
  @Prop({default: []})
  items!: never[]
  @Prop({default: []})
  headers!: Header[]
  @Prop({default: false})
  editAction!: boolean
  @Prop({default: true})
  deleteAction!: boolean
  @Prop({required: false, default: ''})
  name!: string
  @Prop({required: false, default: true})
  enableDownload!: boolean
  @Prop({required: false, default: true})
  enableHeaderConfig!: boolean
  @Prop({required: false, default: true})
  persistHeaders!: boolean
  @Prop({required: false, default: false})
  localizeHeaders!: boolean

  search = ''
  headerConfigs: HeaderConfig[] = []
  showColumnSelector = false
  x = 0
  y = 0

  mounted(): void {
    console.log("localized headers mount")
    this.prepareState(this.localizedHeaders)
  }

  @Watch('localizedHeaders')
  onHeadersChanged(value: Header[]): void {
    console.log("localized headers changed")
    this.prepareState(value)
  }

  @Watch('showColumnSelector')
  onShowColumnSelectorChanged(active: boolean): void {
    if (!active)
      this.saveConfig()
  }

  prepareState(headers: Header[]): void {
    this.headerConfigs = []
    headers.forEach(c => {
      const isActions = c.raw !== (this.localizeHeaders ? 'actions' : this.$t('actions').toString())
      let hc = {header: c, active: true, selectable: !isActions} as HeaderConfig
      this.headerConfigs.push(hc)
    })
    this.loadConf()
  }

  get localizedHeaders(): Header[] {
    console.log("get localized headers")
    let locHdrs: Header[] = []
    this.headers.forEach(hdr => {
      let locHdr: Header = {text: '', raw: ''}
      Object.assign(locHdr, hdr);
      locHdr.raw = hdr.text
      locHdr.text = this.localizeHeaders ? this.$t(hdr.text).toString() : hdr.text
      locHdrs.push(locHdr)
    })
    return locHdrs
  }

  get filteredHeaders(): Header[] {
    return this.headerConfigs.filter(i => i.active).map(i => i.header);
  }

  get selectableHeaders(): HeaderConfig[] {
    return this.headerConfigs.filter(i => !i.selectable);
  }

  saveConfig(): void {
    if (this.name && this.persistHeaders) {
      const hidden = this.headerConfigs.filter(i => !i.active).map(i => i.header.raw)
      const str = JSON.stringify(hidden);
      localStorage.setItem(`CutCloud.tblconf.${this.name}`, str)
      console.log(`config for '${this.name}' stored: ${str}`)
    }
  }

  loadConf(): void {
    if (this.name && this.persistHeaders) {
      const str = localStorage.getItem(`CutCloud.tblconf.${this.name}`)
      if (str) {
        console.log(`config for '${this.name}' available: ${str}`)
        const hidden = JSON.parse(str)
        this.headerConfigs.forEach(c => hidden.includes(c.header.raw) ? c.active = false : c.active = true)
      }
    }
  }

  openColumnSelector(event: any): void {
    event.preventDefault()
    this.showColumnSelector = false
    this.x = event.clientX
    this.y = event.clientY
    this.$nextTick(() => {
      this.showColumnSelector = true
    })
  }

  download(): void {
    const fileName = this.name ? `${this.name}.csv` : 'table-data.csv'
    try {
      console.log(this.items)
      const csv = parse(this.items, {delimiter: ';'})
      const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'})
      saveAs(blob, fileName)
    } catch (err) {
      console.error(err)
    }
  }

  clickRow(item: any): void {
    this.$emit('update:selectrow', item)
  }

  clickDelete(item: any): void {
    this.$emit('update:delete', item)
  }

  clickEdit(item: any): void {
    this.$emit('update:edit', item)
  }

}
