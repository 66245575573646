
import Vue from 'vue'
import {Component} from 'vue-property-decorator'
import PageTitle from '@/components/PageTitle.vue'
import {axiosAuth} from '@/http-common'
import {AxiosError, AxiosResponse} from "axios"
import {userFormatDate} from "@/assets/date_utils";

import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import store from "@/store";

interface Document {
  id: number
  name: string
  fileName: string
  filePath: string
  mimeType: string
  created: Date
}

@Component({
  components: {PageTitle, VuePdfApp}
})
export default class DocumentManagement extends Vue {

  items: Document[] = []
  files: File[] = []
  documentUrl = ''
  selectedDocument: Document | undefined
  isPdf = false
  isImage = false
  loading = false

  headers = [
    {text: 'esab.general.file', value: 'originalName'},
    {text: 'esab.cutting.documents.mimeType', value: 'mimeType'},
    {text: 'esab.general.created', value: 'created', formatter: userFormatDate},
    {text: '', value: 'actions'}
  ]

  mounted(): void {
    this.query()
  }

  query(): void {
    this.loading = true
    axiosAuth.get(`/api/v1/document`).then((response: AxiosResponse<Document[]>) => {
      this.items = response.data
      this.loading = false
    }).catch((error) => {
      console.log(error)
    })
  }

  openDocument(doc: Document): void {
    console.log(doc.fileName)
    this.selectedDocument = doc
    this.isImage = this.selectedDocument?.mimeType.startsWith('image') || false
    this.isPdf = this.selectedDocument?.mimeType.endsWith('pdf') || false
    let path = ""
    if (doc.filePath != null) {
      path = doc.filePath+'/'
    }
    this.documentUrl = Vue.prototype.$backendUrl + '/files/documents/'+ path + doc.fileName;
  }

  deleteDocument(doc: Document): void {
    axiosAuth.delete(`/api/v1/document/${doc.id}`).then((response: AxiosResponse<Document[]>) => {
      console.log(response.data)
      this.query()
      this.selectedDocument = undefined
      this.isPdf = false
      this.isImage = false
      this.documentUrl = ''
    }).catch((error) => {
      console.log(error)
    })
  }

  editDocument(doc: Document): void {
    console.log("edit document: " + doc.fileName)
  }

  addFile(event: any): void {
    let droppedFiles = event.dataTransfer.files
    if (!droppedFiles)
      return;
    ([...droppedFiles]).forEach(f => {
      this.files.push(f)
      this.uploadDocuments()
    })
  }

  removeFile(file: File): void {
    this.files = this.files.filter(f => {
      return f != file
    })
  }

  uploadDocuments(): void {

    let bodyFormData = new FormData()

    this.files.forEach((f, x) => {
      bodyFormData.append('document' + (x + 1), f)
    })

    axiosAuth.post(`/api/v1/document`, bodyFormData, {
      headers: {'Content-Type': 'multipart/form-data'}
    }).then((response: AxiosResponse) => {
      console.log(response.data)
      this.files = []
      this.query()
    }).catch((error: AxiosError) => {
      console.log(error)
      this.files = []
    })

  }
}

