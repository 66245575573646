import Vue from 'vue'
import Vuex from 'vuex'
import {Settings} from "@/model/settings";
import {Moment} from "moment-timezone";

Vue.use(Vuex)

export interface State {
  settingsLoadingState: any
  inProcessState: any
  settings: Settings,
  moment: any
  //username:string,
  // distDigits: number,
  // distUnitFactor: number,
  // distUnitName: string,
  // areaDigits: number,
  // areaUnitFactor: number,
  // areaUnitName: string,
  // weightDigits: number,
  // weightUnitFactor: number,
  // weightUnitName: string,
  // dateFormat: string,
  // dateFormatShort: string,
  // timeFormat: string,
  // timeFormatShort: string
}

export default new Vuex.Store({
  state: {
    settingsLoadingState : true,
    inProcessState : true
  } as State,
  mutations: {
    setLoadingStates(states, value){
      states.settingsLoadingState = value
    },
    setInProcessState(states, value){
      states.inProcessState = value
    }
  },
  actions: {},
  modules: {}
})
