
import Vue from "vue";
import {Component, Watch} from "vue-property-decorator";
import {MachineGroup} from "@/model/machinegroup";
import {Machine} from "@/model/machine";
import {axiosAuth} from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";

@Component({
  components: {}
})
export default class ManageMachineGroups extends Vue {

  groups: MachineGroup[] = []
  selectedGroups: MachineGroup[] = []
  machines: Machine[] = []
  selectedMachines: Machine[] = []
  selectionUpdating = false

  mounted(): void {
    this.query()
  }

  query(): void {
    axiosAuth.get(`/api/v1/machinegroup`).then((response: AxiosResponse<MachineGroup[]>) => {
      this.groups = response.data
    }).catch((error: AxiosError) => {
      console.log(error)
    })

    axiosAuth.get(`/api/v1/machines`).then((response: AxiosResponse<Machine[]>) => {
      this.machines = response.data
    }).catch((error: AxiosError) => {
      console.log(error)
    })
  }

  @Watch('selectedMachines')
  onSelectedMachinesChanged(machines: Machine[]): void {
    if (this.selectionUpdating)
      return
    this.selectionUpdating = true;
    this.$emit('update:selected', this.selectedMachines)
    this.selectionUpdating = false;
  }

  @Watch('selectedGroups')
  onSelectedGroupsChanged(groups: MachineGroup[]): void {
    if (this.selectionUpdating)
      return
    this.selectionUpdating = true;

    const newMachineSet = new Set<string>()
    groups.forEach(g => {
      g.machineGroupPositions.forEach(gm => {
        newMachineSet.add(gm.machine.name)
      })
    })
    const newMachines = [...newMachineSet]
    const selectedMachines: Machine[] = []
    this.machines.forEach(m => {
      if (newMachines.includes(m.name))
        selectedMachines.push(m)
    })
    this.selectedMachines = selectedMachines
    this.$emit('update:selected', this.selectedMachines)
    this.selectionUpdating = false;
  }

  get groupHeaders() {
    return [
      {text: this.$t('esab.cutting.machineGroups.machineGroups'), value: 'name'},
    ]
  }

  get machineHeaders() {
    return [
      {text: this.$t('esab.cutting.machines.machines'), value: 'name'},
    ]
  }
}
