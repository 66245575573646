
import Vue from 'vue'
import {Component} from 'vue-property-decorator'
import PageTitle from '@/components/PageTitle.vue'
import {axiosAuth} from '@/http-common'
import {AxiosResponse} from 'axios'
import {Event} from '@/model/event'
import {userFormatDate} from '@/assets/date_utils'
import store from "@/store";

@Component({
  components: {PageTitle}
})
export default class EventList extends Vue {

  items: Event[] = []
  loading = false

  headers = [
    {text: 'esab.cutting.machines.machine', value: 'machine.name'},
    {text: 'esab.cutting.ncProgram', value: 'ncProgram'},
    {
      text: 'esab.cutting.events.beginEvent',
      value: 'eventStart',
      formatter: userFormatDate
    },
    {text: 'esab.cutting.events.event', value: 'eventText'},
    {text: 'esab.cutting.machines.errorGroups.errorGroup', value: 'errorGroup.name'},
    {
      text: this.$t('esab.cutting.events.eventLen') + ' ' + '[s]',
      value: 'eventLen'
    },
    {text: 'esab.cutting.operators.operator', value: 'operator.person.name'}
  ]

  mounted(): void {
    this.query()
  }

  query(): void {
    this.loading = true
    axiosAuth.get(`/api/v1/event`).then((response: AxiosResponse<Event[]>) => {
      this.items = response.data
      this.loading = false
    }).catch((error) => {
      console.log(error)
      this.loading = false
    })
  }
}

