
import Vue from 'vue'
import {axiosAuth} from '@/http-common'
import {AxiosResponse} from 'axios'
import {Machine} from '@/model/machine'
import MachineInfoPane from '@/components/MachineInfoPane.vue'
import PageTitle from '@/components/PageTitle.vue'
import Events from '@/components/Events.vue'
import PdmData from '@/components/PdmData.vue'
import CurrentProgramPane from '@/components/CurrentProgramPane.vue'
import {CurrentEvent} from '@/model/currentevent'

export default Vue.extend({
  name: 'machine-info',
  components: {CurrentProgramPane, PdmData, Events, PageTitle, MachineInfoPane},
  data() {
    return {
      tab: 'info',
      updateInterval: window.setTimeout(function () {
        console.log('init Interval')
      }, 0),
      tabs: ['esab.cutting.machines.info', 'esab.cutting.ncProgram', 'esab.cutting.pdmData.pdmData', 'esab.cutting.events.events'],
      machineName: undefined as string | undefined,
      machineId: undefined as string | undefined,
      machine: undefined as Machine | undefined,
      machines: [] as Machine[],
      machineNames: [] as string[],
      machineIds: [] as string[],
      currentEvent: {} as CurrentEvent,
      search: '',
      loading: false
    }
  },
  mounted() {
    this.query()
    clearInterval(this.updateInterval)
    this.updateInterval = window.setInterval(() => {
      this.queryInterval()
    }, 3000)
  },
  beforeRouteLeave: function(to: any, from: any, next: () => void){
    clearInterval(this.updateInterval)
    this.loading = false;
    next();
  },
  methods: {
    query() {
      if (!this.loading) {
        const machine = this.$route.params.machine
        this.loading = true
        axiosAuth.get(`/api/v1/machines`).then((response: AxiosResponse<Machine[]>) => {
          this.machines = response.data
          this.machine = this.machines.find(m => m.name === machine)
          this.machineName = this.machine?.name
          this.machineId = this.machine?.id
          this.machineNames = this.machines.map(m => m.name)
          this.machineIds = this.machines.map(m => m.id)
          this.loading = false
          if ((this.machineName == undefined)  && (this.machines.length > 0)) {
            this.machineName = this.machines[0].name
            this.machineId = this.machines[0].id
          }

        }).catch((error) => {
          this.loading = false
          console.log(error)
        })

        if (this.machineName != undefined) {
          axiosAuth.get(`/api/v1/machinecurrentevent/machineid/${this.machineId}`).then((response: AxiosResponse<CurrentEvent>) => {
            this.currentEvent = response.data
            this.loading = false
          }).catch((error) => {
            this.loading = false
            console.log(error)
          })
        }
        else{
          this.loading = false
        }
        //this.loading = false
      }
    },
    queryInterval() {
      if (!this.loading) {
        const machine = this.$route.params.machine
        if (machine == undefined)
          return
        this.loading = true
        axiosAuth.get(`/api/v1/machines`).then((response: AxiosResponse<Machine[]>) => {
          this.machine = response.data.find(m => m.name === machine)
        }).catch((error) => {
          console.log(error)
        })
        if (this.machineName != undefined) {
          axiosAuth.get(`/api/v1/machinecurrentevent/machineid/${this.machineId}`).then((response: AxiosResponse<CurrentEvent>) => {
            this.currentEvent = response.data
            this.loading = false
          }).catch((error) => {
            console.log(error)
          })
        }
        this.loading = false;
      }
    }
  },
  watch: {
    machineName: function (value: any) {
      if (this.$route.params.machine !== value) {
        this.$router.push({name: 'MachineInfo', params: {machine: value}})
        this.query()
      }
    }
  }
})
