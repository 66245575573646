<template>
  <v-app>
    <div v-if="$store.state.settingsLoadingState" class="centerLoader">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </div>
  <template v-else>
    <v-navigation-drawer app clipped :mini-variant="miniVariant" v-model="drawer" color="#EEE4DE">
      <v-list nav>
        <v-list-item v-for="(item, i) in items" :key="i" :to="item.to" router>
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"/>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app fixed clipped-left :color="topbarBgColor">
      <v-btn icon @click.stop="miniVariant = !miniVariant" color="secondary">
        <v-icon>mdi-{{ `chevron-${miniVariant ? 'right' : 'left'}` }}</v-icon>
      </v-btn>
      <img src="/img/icoCutCloud.svg" alt="Logo" style="height: 52px" class="mx-2">
      <!--      <v-toolbar-title v-text="title" class="mx-2 cc_title" style="color: #fff"/>-->
      <v-toolbar-title v-text="titlePart1" class="mx-0 cc_title1" style="color: #fff"/>
      <v-toolbar-title v-text="titlePart2" class="mx-0 cc_title2" style="color: #fff"/>
      <v-toolbar-title v-text="titlePart3" class="mx-2 cc_title3" style="color: #fff"/>
      <v-spacer/>

      <!--      <v-chip v-if="settings.gatewayId" label color="white" class="mx-10" style="min-height: 35px">-->
      <!--        <span>Gateway-Id: <code>{{ settings.gatewayId }}</code></span>-->
      <!--&lt;!&ndash;        <img v-if="ownerLogoUrl" :src="ownerLogoUrl" alt="Logo" style="height: 37px" class="mx-2">&ndash;&gt;-->
      <!--      </v-chip>-->

      <v-menu v-if="user" offset-y>

        <template v-slot:activator="{ on }">
          <!--          <v-chip label color="secondary" class="mx-0" style="min-height: 48px">-->
          <!--            <v-chip label color="#EAEAEAFF" style="min-height: 35px">-->
          <!--              <img v-if="ownerLogoUrl" :src="ownerLogoUrl" alt="Logo" class="mx-2" style="height: 37px">-->
          <!--              <span style="font-family: 'Roboto'; font-weight: bold; font-size: larger; text-shadow: 2px 2px 2px #BBB; color: #333">Europe Steel Center</span>-->
          <!--            </v-chip>-->
          <!--            <v-btn v-on="on" x-large elevation="0" color="secondary">-->
          <!--&lt;!&ndash;              <span class="pr-3">{{ user.name }}</span>&ndash;&gt;-->
          <!--              <v-avatar size="36px" v-if="user.avatar!==''">-->
          <!--                <img :src="user.avatar" alt="Avatar">-->
          <!--              </v-avatar>-->
          <!--              <v-icon right x-large v-if="user.avatar===''">-->
          <!--                mdi-account-circle-->
          <!--              </v-icon>-->
          <!--            </v-btn>-->
          <!--          </v-chip>-->
          <v-btn v-on="on" elevation="0" color="#DADADA00">
            <!--              <span class="pr-3">{{ user.name }}</span>-->
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-user" role="img"
                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                 class="svg-inline--fa fa-circle-user v-icon__component theme--light"
                 style="font-size: 34px; height: 34px; width: 34px;">
              <path fill="#fff"
                    d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c39.77 0 72 32.24 72 72S295.8 272 256 272c-39.76 0-72-32.24-72-72S216.2 128 256 128zM256 448c-52.93 0-100.9-21.53-135.7-56.29C136.5 349.9 176.5 320 224 320h64c47.54 0 87.54 29.88 103.7 71.71C356.9 426.5 308.9 448 256 448z"
                    class=""></path>
            </svg>
            <!--            <v-avatar size="36px" v-if="user.avatar!==''">-->
            <!--              <img :src="user.avatar" alt="Avatar">-->
            <!--            </v-avatar>-->
            <!--            <v-icon right x-large v-if="user.avatar===''">-->
            <!--              mdi-account-circle-->
            <!--            </v-icon>-->
          </v-btn>
          <v-chip :color="ownerBgColor" style="min-height: 38px;pointer-events: none">
            <img v-if="ownerLogoUrl" :src="ownerLogoUrl" alt="Logo" class="mx-0" style="height: 32px">
            <span v-if="ownerName" class="mx-4" :style="cssPropsOwnerStyle">{{ ownerName }}</span>
            <span v-if="isNotProductionEnviroment">Gateway-Id: <code>{{ ownerGatwayId }}</code></span>
          </v-chip>
        </template>

        <v-list>
          <v-list-item @click="showUserProfile">
            <v-list-item-title>{{ $t('esab.cutting.users.editUser') }} : {{ user.name }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showPasswordChange=true">
            <v-list-item-title>{{ $t('esab.general.changePassword') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title>{{ $t('esab.general.logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-if="!user" @click="showLogin=true" x-large elevation="0" color="secondary">
        <span class="pr-2">{{ $t('esab.general.login') }}</span>
        <v-icon right large class="pr-1">mdi-account-circle-outline</v-icon>
      </v-btn>
    </v-app-bar>

    <v-dialog v-model="showLogin" width="480">
      <login-pane @update:login="login($event)"/>
    </v-dialog>

    <v-dialog v-model="showProfile" width="480" persistent>
      <profile-pane :user-name="this.myUsername" @update:close="showProfile=false"/>
    </v-dialog>

    <v-dialog v-model="showPasswordChange" v-if="user" width="480" persistent>
      <change-password-pane :user-name="this.myUsername"
                            @update:close="showPasswordChange=false"
                            @update:message="showMessage"/>
    </v-dialog>

    <v-main>
      <router-view/>
    </v-main>

    <gti-message v-model="messageVisible" :message="message"/>

    <v-footer app>
      <div style="text-align: center; width: 100%">
<!--        v{{ version }}&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&copy; 2017-2023 ESAB / Digitron-->
      </div>
    </v-footer>
  </template>

  </v-app>
</template>

<script>
import Vue from 'vue'
import LoginPane from '@/components/LoginPane'
import ProfilePane from '@/components/ProfilePane'
import {
  isTokenExpired,
  clearUserFromLocalStorage,
  getUserFormToken,
  getUserFromLocalStorage,
  startTokenExpiryCheck,
  storeUserInLocalStorage, isAdmin, updateUiLanguage
} from '@/assets/auth_utils'
import {axiosAuth} from '@/http-common'
import ChangePasswordPane from '@/components/ChangePasswordPane'
import CuttingMachineIcon from '@/components/CustomCuttingMachineIcon.vue';
import CuttingMachineGroupIcon from '@/components/CustomCuttingMachineGroupIcon.vue';
import CuttingMachineOperatorIcon from '@/components/CustomCuttingMachineOperatorIcon.vue';
import store from "@/store";
import {AxiosError, AxiosResponse} from "axios";
import {Settings} from "@/model/settings";
import profilePane from "@/components/ProfilePane";
import {userTranslate, userTranslateExtent} from "@/assets/date_utils";
import i18n from "@/plugins/i18n";

export default Vue.extend({
  name: 'App',
  components: {ChangePasswordPane, ProfilePane, LoginPane},
  icons: {
    iconfont: 'faSvg',
    values: {
      cutcloudMachine: {
        component: CuttingMachineIcon,
        props: {
          icon: 'cuttingMachine'
        }
      },
      cutcloudMachineGroups: {
        component: CuttingMachineGroupIcon,
        props: {
          icon: 'cuttingMachineGroups'
        }
      },
      cutcloudMachineOperators: {
        component: CuttingMachineOperatorIcon,
        props: {
          icon: 'cuttingMachineOperator'
        }
      }
    }
  },
  created() {
    this.user = getUserFromLocalStorage()
    // this.$user = this.user

    if (isTokenExpired()) {
      console.log('expireed token found, logging out')
      this.logout()
    }

    startTokenExpiryCheck(2, 5, () => {
      console.log('trying to refresh token')
      axiosAuth.get(`/token`).then(response => {
        console.log('refresh successful')
        const user = getUserFormToken(response.data.bearerToken)
        storeUserInLocalStorage(user)
        this.user = user
      }).catch(error => {
        console.log('error while refreshing token, logging out')
        console.log(error)
        this.logout()
      })
    })
    store.commit('setLoadingStates', true)
    this.downloadSettings()
  },
  mounted(){
    //this.downloadSettings()
  },
  data() {
    return {
      title: 'CutCloud Gateway',
      titlePart1: 'Cut',
      titlePart2: 'Cloud',
      titlePart3: 'Gateway',
      version: '3.1.0-RC',
      showLogin: false,
      showProfile: false,
      showPasswordChange: false,
      user: null,
      miniVariant: false,
      drawer: true,
      fixed: false,
      message: {},
      messageVisible: false,
      layoutFileExt: '.jpg',
      languageIsLoaded: false,
      myUsername: '#user#',
      settings: store.state.settings
    }
  },
  methods: {
    showUserProfile() {
      this.myUsername = this.user.name
      this.showProfile = true
    },
    login(user) {
      this.user = user
      console.log("LoginName: " + user.name)
      this.showLogin = false
      this.languageIsLoaded = false
      this.downloadSettings()
      this.languageIsLoaded = false
      if (this.$router.currentRoute.path !== '/')
        this.$router.push({path: '/'})
    },
    logout() {
      this.user = null
      clearUserFromLocalStorage()
      if (this.$router.currentRoute.path !== '/')
        this.$router.push({path: '/'})
    },
    showMessage(message) {
      this.message = message
      this.messageVisible = true
    },
    downloadSettings() {
      if (this.user == undefined) {
        const username = localStorage.getItem("auth.user")
        console.log("Username (OUT): "+username)

        updateUiLanguage(undefined, false)
        console.log('settings download (1)')
      } else {
        const username = localStorage.getItem("auth.user")
        console.log("Username (IN): "+username)
        this.myUsername = username
        console.log("MyUsername (IN): "+this.myUsername)

        this.showProfile = false

        axiosAuth.get(`/api/v1/settings/`+username).then((response) => {
          store.state.settings = response.data
          this.settings = store.state.settings
          store.state.moment = require('moment-timezone')
          let lng = store.state.settings.userLanguage
          if (lng == "en")
            lng = store.state.moment.locale("en-gb");
          else
            lng = store.state.moment.locale(store.state.settings.userLanguage);

          this.showProfile = false

          if (!this.languageIsLoaded) {
            updateUiLanguage(this.settings.userLanguage, true)
            this.languageIsLoaded = true
          }
        }).catch((error) => {
          console.log("Setting:" + error.message)
        })
        console.log('settings download (2)')
      }
    },
  },
  computed: {
    ownerName() {
      try {
        if (this.settings.ownerName)
          return this.settings.ownerName
        else
          return ''
      } catch (e) {
        return ''
      }
    },
    ownerGatwayId() {
      try {
        if (this.settings.gatewayId)
          return this.settings.gatewayId
        else
          return ''
      } catch (e) {
        return ''
      }
    },
    ownerLogoUrl() {
      try {
        if (this.settings.ownerLogoFile)
          return this.$backendUrl + '/images/misc/' + this.settings.ownerLogoFile
        else
          return ''
      } catch (e) {
        return ''
      }
    },
    topbarBgColor() {
      return '#D17C2C' //this.settings.logoColor || '#D17C2C' // std. ESAB color #f4eb20 ESAB_Yellow
    },
    ownerBgColor() {
      try {
        if (this.settings.ownerBgColor)
          return this.settings.ownerBgColor
        else
          return ''
      } catch (e) {
        return "#FFF"
      }
    },
    ownerColor() {
      try {
        if (this.settings.ownerColor)
          return this.settings.ownerColor
        else
          return '#000000'
      } catch (e) {
        return "#000000"
      }
    },
    cssPropsOwnerStyle() {
      var col = '#000'
      if (this.settings.ownerColor)
        col = this.settings.ownerColor
      return {
        'font-family': 'Roboto',
        'color': col,
        'font-weight': 'normal',
        'font-size': 'larger',
        'text-shadow': '1px 1px 1px #999'
      }
    },
    isNotProductionEnviroment() {
      if (process.env.NODE_ENV !== 'production')
        return true;
      else
        return false;
    },
    items() {
      let items = [
        {
          //icon: 'mdi-home',
          icon: 'fa-home',
          title: userTranslate('esab.cutting.start'),
          to: '/',
          access: 'anonymous'
        },
        {
          //icon: 'mdi-cog',
          icon: 'fa-cog',
          title: this.$t('esab.cutting.machines.machines'),
          to: '/machine-info'
        },
        {
          //icon: 'mdi-cogs',
          icon: 'fa-cogs',
          title: this.$t('esab.cutting.machineGroups.machineGroups'),
          to: '/machine-groups'
        },
        {
          icon: 'fa-users-cog',
          title: this.$t('esab.cutting.operators.operators'),
          to: '/operator-list'
        },
        {
          //icon: 'mdi-card-multiple-outline',
          icon: 'fa-layer-group',
          title: this.$t('esab.cutting.sheets.sheets'),
          to: '/list-sheets'
        },
        {
          //icon: 'mdi-calendar',
          icon: 'fa-exclamation-circle',
          title: this.$t('esab.cutting.events.events'),
          to: '/list-events'
        },
        {
          //icon: 'mdi-chart-areaspline',
          icon: 'fa-chart-area',
          title: this.$t('esab.cutting.reports.reports'),
          to: '/reports'
        },
        {
          //icon: 'mdi-file-document-multiple-outline',
          icon: 'fa-copy',
          title: this.$t('esab.cutting.documents.documents'),
          to: '/document-management'
        },
        {
          //icon: 'mdi-account-multiple-check',
          icon: 'fa-users',
          title: this.$t('esab.cutting.users.management'),
          to: '/user-management',
          requiredRoles: 'admin'
        },
        {
          //icon: 'mdi-account-multiple-check',
          icon: 'fa-cogs',
          title: this.$t('esab.cutting.machines.management'),
          to: '/machine-management',
          requiredRoles: 'admin'
        },
        {
          //icon: 'mdi-certificate-outline',
          icon: 'fa-award',
          title: this.$t('esab.cutting.licenses.license'),
          to: '/license',
          // requiredRoles: 'admin'
          access: 'anonymous'
        },
        {
          //icon: 'mdi-phone',
          icon: 'fa-phone',
          title: this.$t('esab.general.contact'),
          to: '/contact',
          access: 'anonymous'
        },
        {
          // icon: 'mdi-microsoft-azure',
          //icon: 'mdi-bug',
          icon: 'fa-bug',
          //icon: 'fa-network-wired',
          title: this.$t('esab.general.debug'),
          to: '/edge',
          requiredRoles: 'admin'
        }
      ]
      if (process.env.NODE_ENV !== 'production')
        items.push(
            {
              //icon: 'mdi-bullseye',
              icon: 'fa-bullseye',
              title: 'Upload-Test',
              to: '/upload'
            }
        )
      if (!this.user)
        items = items.filter(i => i.access === 'anonymous')

      items = items.filter(i => i.requiredRoles !== 'admin' || isAdmin())
      return items
    }
  }
})
</script>

<style scoped>

.centerLoader{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cc_title {
  font-size: 26px !important;
  font-weight: bold;
  color: #444;
  text-shadow: 1px 1px 3px gray;
}

.cc_title1 {
  /*font-family: Museo Sans ,sans-serif !important;*/
  font-size: 26px !important;
  font-weight: normal;
  color: #444;
  text-shadow: 1px 1px 3px gray;
}

.cc_title2 {
  font-size: 26px !important;
  font-weight: lighter;
  color: #444;
  text-shadow: 1px 1px 3px gray;
}

.cc_title3 {
  font-size: 26px !important;
  font-weight: normal;
  font-style: revert;
  color: #444;
  text-shadow: 1px 1px 3px gray;
}
</style>
