
import Vue from 'vue'
import {Component} from "vue-property-decorator";
import PageTitle from '@/components/PageTitle.vue'
import {axiosAuth} from '@/http-common';
import {AxiosResponse} from 'axios'
import {Operator} from '@/model/operator'
import {userFormatDate, userTranslate} from "@/assets/date_utils";
import {getLanguageByLanguageCode} from "@/assets/auth_utils";

@Component({
  components: {PageTitle}
})
export default class OperatorList extends Vue {

  items: Operator[] = []
  loading = false

  headers = [
    //{text: 'esab.cutting.operators.operatorId', value: 'externalID'},
    {text: 'esab.cutting.operators.operatorName', value: 'person.name'},
    {text: 'esab.cutting.users.userEmail', value: 'person.email'},
    {text: 'esab.cutting.users.userLastLogin', value: 'person.lastLogin', formatter: userFormatDate},
    {text: 'esab.cutting.storageLocations.storageLocation', value: 'person.storageLocationName', formatter: userTranslate},
    {text: 'esab.general.active', value: 'person.active'},
    {text: 'esab.general.created', value: 'person.created' , formatter: userFormatDate }
    // {text: 'esab.cutting.users.userDeleted', value: 'person.deleted' , formatter: userFormatDate}
  ]

  mounted(): void {
    this.query()
  }

  query(): void {
    this.loading = true
    axiosAuth.get(`/api/v1/operators`).then((response: AxiosResponse<Operator[]>) => {
      this.items = response.data
      this.items.forEach(e => {
        e.person.storageLocationName = (e.person.storageLocation.toString() == '00000000-0000-0000-0000-000000000002') ? 'esab.cutting.storageLocations.gateway' : 'esab.cutting.storageLocations.allGateways'
        e.person.active = ((e.deactivated == undefined) && (e.person.deactivated == undefined))
      })
      this.loading = false
    }).catch((error) => {
      console.log(error)
    })
  }
}
