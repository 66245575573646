
import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import moment from "moment";

@Component({
  components: {}
})
export default class GtiDateField extends Vue {

  @Prop({required: true})
  value!: Date
  @Prop({required: true})
  label!: string

  text = ''
  valueUpdating = false

  mounted(): void {
    this.text = this.formatDate(this.value)
  }

  @Watch('value')
  onValueChanged(value: Date): void {
    if (this.valueUpdating)
      return
    this.valueUpdating = true;
    this.$emit('update:valueChanged', this.valueUpdating)
    this.valueUpdating = false;

    this.text = this.formatDate(value)
  }

  get inputRules(): any {
    return [
      (v: string) => this.dateValid(v) || this.$t('esab.general.validation.invalidDate').toString()
    ]
  }

  get dateFormat(): string {
    return 'DD.MM.YYYY';
    //return this.$t('dateFormat').toString() || '';
  }

  @Watch('dateFormat')
  onDateFormatChanged(): void {
    this.text = this.formatDate(this.value)
  }

  expandDateAndCommit(): void {
    this.expandDate()
    this.$emit("input", this.parseDate(this.text))
  }

  expandDate(): void {
    if (this.text != null && this.text != undefined && this.text.length >= 8 && this.dateValid(this.text))
      return
    const div = parseInt(this.text)
    if (!isNaN(div)) {
      this.text = moment().add(this.text, "hours").format(this.dateFormat)
    }
  }

  dateValid(value: string): boolean {
    if (!value)
      return true
    const div = parseInt(value)
    if (!isNaN(div))
      return true
    return moment(value, this.dateFormat).isValid()
  }

  parseDate(date: string): Date {
    return moment(date, this.dateFormat).toDate()
  }

  formatDate(date: Date): string {
    return moment(date).format(this.dateFormat)
  }

}
