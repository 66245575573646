import Vue from 'vue'
import Component from 'vue-class-component'
import {axiosAuth} from "@/http-common";
import {AxiosResponse} from "axios";

// TODO: reimplement statehandling

interface MachineState {
  machineStatus: number,
  description: string,
  color: string
}

interface Color {
  key: string,
  code: string
}

export interface State {
  id: number,
  key: string,
  color: string
}

@Component
export class MachineStatesMixin extends Vue {

  private states: State[] = []

  private colors: Color[] = []

  created(): void {
    this.colors = [
      {key: 'BLUE', code: '#2196F3'},
      {key: 'GREEN', code: '#4CAF50'},
      {key: 'YELLOW', code: '#FFEB3B'},
      {key: 'RED', code: '#F44336'}
    ]

    axiosAuth.get(`/api/v1/machinestate`).then((response: AxiosResponse<MachineState[]>) => {
      const raw = response.data as MachineState[]
      this.states = raw.map(r => {
        return {
          id: r.machineStatus,
          key: r.description,
          color: this.colors.find(c => c.key == r.color)?.code || '#FFFFFF'
        }
      })
    }).catch((error) => {
      console.log(error)
    })
  }

  public getColorForState(state: string): string {
    return this.states.find(s => s.key == state)?.color || '#FFFFFF'
  }

  public getColorForStateColor(color: string): string {
    return this.colors.find(c => c.key == color)?.code || '#FFFFFF'
  }

}
