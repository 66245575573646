<template>
  <img ref="image" :src="src" style="width: 100%" v-cloak @drop.prevent="setFile" @dragover.prevent/>
</template>

<script>

export default {
  name: 'ImageDrag',
  props: {
    w: {
      type: Number,
      required: false,
      default: 400
    },
    h: {
      type: Number,
      required: false,
      default: 300
    },
    src: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      image: null
    }
  },
  methods: {
    setFile(event) {
      this.image = event.dataTransfer.files[0]
    },
    clearFile(file) {
      this.image = null
    },
    singlePreview(file) {
      let image = this.$refs['image']
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function () {
        image.src = reader.result
        image.onload = function () {
          URL.revokeObjectURL(image.src) // free memory
        }
      }
    }
  },
  watch: {
    image(file) {
      this.$emit('update:image', file)
      if (file)
        this.singlePreview(file)
    }
  }
}
</script>
