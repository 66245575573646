<template>
  <v-layout wrap>
    <v-flex lg6>
      <!--      <gti-app-bar title="currProg.layouts"/>-->
      <!--      <v-data-table :items="layouts" :headers="layoutHeaders" :items-per-page="1" hide-default-footer-->
      <!--                    item-key="idx"></v-data-table>-->
      <gti-data-table title="esab.cutting.layouts.layoutInfo" :items="layouts" :headers="layoutHeaders" :localize-headers="false"
                      name="layoutData"/>
      <!--      <gti-app-bar title="currProg.sheets"/>-->
      <!--      <v-data-table :items="sheets" :headers="sheetHeaders" :items-per-page="5" hide-default-footer
                          item-key="idx"></v-data-table>-->
      <gti-data-table title="esab.cutting.sheets.sheets" :items="sheets" :headers="sheetHeaders" :localize-headers="false"
                      name="sheetData"/>
    </v-flex>
    <v-flex md6 pa-16>
      <v-img :src="layoutImage" contain/>
    </v-flex>
    <v-flex>
      <!--      <gti-app-bar title="currProg.orders"/>-->
      <!--      <v-data-table :items="orders" :headers="orderHeaders" hide-default-footer item-key="idx"></v-data-table>-->
      <gti-data-table title="esab.cutting.orders.orders" :items="orders" :headers="orderHeaders" :localize-headers="false"
                      name="pdmData"/>
    </v-flex>

  </v-layout>
</template>

<script>
import {axiosAuth} from '@/http-common'
import {decimals, userFormatDate} from '@/assets/date_utils'
import store from "@/store";

export default {
  name: 'CurrentProgramPane',
  data() {
    return {
      layouts: [],
      sheets: [],
      orders: []
    }
  },
  props: ['programName'],
  mounted() {
    this.query()
  },
  watch: {
    programName() {
      console.log(this.programName)
      this.query()
    }
  },
  computed: {
    layoutImage() {
      return this.$backendUrl + '/externalfiles/layouts/' + this.programName + store.state.settings.layoutFileExt
    },
    layoutHeaders() {
      return [
        // {text: this.$t('programdata.id'), value: 'idx'},
        {text: this.$t('esab.cutting.layouts.layoutName'), value: 'layoutName'},
        {
          text: this.$t('esab.general.width') + ' ' + '[' + store.state.settings.distUnitName + ']',
          value: 'width',
          formatter: decimals,
          param: {digits: store.state.settings.distDigits, unitFactor: store.state.settings.distUnitFactor, factor: 1.0}
        },
        {
          text: this.$t('esab.general.height') + ' ' + '[' + store.state.settings.distUnitName + ']',
          value: 'height',
          formatter: decimals,
          param: {digits: store.state.settings.distDigits, unitFactor: store.state.settings.distUnitFactor, factor: 1.0}
        },
        {
          text: this.$t('esab.cutting.materials.thickness') + ' ' + '[' + store.state.settings.distUnitName + ']',
          value: 'thickness',
          formatter: decimals,
          param: {digits: store.state.settings.distDigits, unitFactor: store.state.settings.distUnitFactor, factor: 1.0}
        },
        //{text: this.$t('programdata.sheetName'), value: 'sheetName'},
        {text: this.$t('esab.cutting.materials.material'), value: 'materialName'},
        {text: this.$t('esab.cutting.machines.machine'), value: 'machineName'},
        {text: this.$t('esab.general.quality'), value: 'quantity'},
        {text: this.$t('esab.cutting.layouts.userChanged'), value: 'userChanged'},
        {
          text: this.$t('esab.cutting.layouts.dateChanged'),
          value: 'dateChanged',
          formatter: userFormatDate
        },
        {
          text: this.$t('esab.cutting.layouts.dateStateChanged'),
          value: 'dateStateChanged',
          formatter: userFormatDate
        },
        {
          text: this.$t('esab.cutting.layouts.dateCodeGenerated'),
          value: 'dateCodeGenerated',
          formatter: userFormatDate
        }
        // {text: this.$t('programdata.serial'), value: 'layoutSerial'},
        // {text: this.$t('programdata.order'), value: 'orderName'},
        // {text: this.$t('programdata.note'), value: 'note'},
        // {text: this.$t('programdata.part'), value: 'partName'}
      ]
    },
    sheetHeaders() {
      return [
        // {text: this.$t('programdata.id'), value: 'idx'},
        // {text: this.$t('programdata.name'), value: 'layoutName'},
        {text: this.$t('esab.cutting.sheets.sheetName'), value: 'sheetName'},
        {text: this.$t('esab.general.serialNumber'), value: 'serialNumber'},
        {text: this.$t('esab.cutting.materials.placement'), value: 'placement'},
        {
          text: this.$t('esab.general.width') + ' ' + '[' + store.state.settings.distUnitName + ']',
          value: 'width',
          formatter: decimals,
          param: {digits: store.state.settings.distDigits, unitFactor: store.state.settings.distUnitFactor, factor: 1.0}
        },
        {
          text: this.$t('esab.general.height') + ' ' + '[' + store.state.settings.distUnitName + ']',
          value: 'height',
          formatter: decimals,
          param: {digits: store.state.settings.distDigits, unitFactor: store.state.settings.distUnitFactor, factor: 1.0}
        },
        {
          text: this.$t('esab.cutting.materials.thickness') + ' ' + '[' + store.state.settings.distUnitName + ']',
          value: 'thickness',
          formatter: decimals,
          param: {digits: store.state.settings.distDigits, unitFactor: store.state.settings.distUnitFactor, factor: 1.0}
        },
        {text: this.$t('esab.cutting.materials.material'), value: 'materialName'}
      ]
    },
    orderHeaders() {
      return [
        // {text: this.$t('programdata.id'), value: 'idx'},
        // {text: this.$t('programdata.name'), value: 'layoutName'},
        {text: this.$t('esab.cutting.orders.order'), value: 'order'},
        {text: this.$t('esab.general.partId'), value: 'partName'},
        // {text: this.$t('programdata.geometry'), value: 'geometryName'},
        {
          text: this.$t('esab.general.width') + ' ' + '[' + store.state.settings.distUnitName + ']',
          value: 'width',
          formatter: decimals,
          param: {digits: store.state.settings.distDigits, unitFactor: store.state.settings.distUnitFactor, factor: 1.0}
        },
        {
          text: this.$t('esab.general.height') + ' ' + '[' + store.state.settings.distUnitName + ']',
          value: 'height',
          formatter: decimals,
          param: {digits: store.state.settings.distDigits, unitFactor: store.state.settings.distUnitFactor, factor: 1.0}
        },
        {
          text: this.$t('esab.general.area') + ' ' + '[' + store.state.settings.areaUnitName + ']',
          value: 'area',
          formatter: decimals,
          param: {digits: store.state.settings.areaDigits, unitFactor: store.state.settings.areaUnitFactor, factor: 0.000001}
        },
        {
          text: this.$t('esab.general.weight') + ' ' + '[' + store.state.settings.weightUnitName + ']',
          value: 'weight',
          formatter: decimals,
          param: {
            digits: store.state.settings.weightDigits,
            unitFactor: store.state.settings.weightUnitFactor,
            factor: 1
          }
        },
        {
          text: this.$t('esab.cutting.materials.delivered'),
          value: 'delivered',
          formatter: userFormatDate
        },
        {text: this.$t('esab.general.category1'), value: 'cat1'},
        {text: this.$t('esab.general.category2'), value: 'cat2'},
        {text: this.$t('esab.cutting.orders.orderedCount'), value: 'orderedCount'},
        {text: this.$t('esab.cutting.orders.nestedCount'), value: 'nestedCount'}
      ]
    }
  },
  methods: {
    query() {
      axiosAuth.get(`/api/v1/columbus/layout/${this.programName}`).then((response) => {
        let idx = 0
        response.data.forEach(i => i.idx = idx++)
        this.layouts = response.data
      }).catch((error) => {
        console.log(error)
      })
      axiosAuth.get(`/api/v1/columbus/sheet/${this.programName}`).then((response) => {
        let idx = 0
        response.data.forEach(i => i.idx = idx++)
        this.sheets = response.data
      }).catch((error) => {
        console.log(error)
      })
      axiosAuth.get(`/api/v1/columbus/order/${this.programName}`).then((response) => {
        let idx = 0
        response.data.forEach(i => i.idx = idx++)
        this.orders = response.data
      }).catch((error) => {
        console.log(error)
      })
    }
  }
}
</script>
