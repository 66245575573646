
import Vue, {computed} from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import {MachineStatesMixin} from '@/assets/machinestates_mixin'
import InfoLine from '@/components/InfoLine.vue'
import {Machine} from '@/model/machine'
import {MachineGroupPosition} from '@/model/machinegroupposition'
import {axiosAuth} from '@/http-common'
import {AxiosResponse} from 'axios'
import {CurrentEvent} from '@/model/currentevent'

@Component({
  components: {InfoLine}
})
export default class MachinesPane extends mixins(MachineStatesMixin) {

  @Prop({required: true,default: () => []})
  machines!: Machine[]

  queryKey = 0

  updateInterval: any

  loading = false

  @Watch('machines')
  onMachinesChange() {
    this.loading = false
    this.query()
  }

  created(): void{
    this.updateInterval = window.setTimeout(function () { console.log('init Interval') }, 0)
  }

  mounted(): void {
    this.loading = false
    this.query()
    clearInterval(this.updateInterval)
    this.updateInterval = window.setInterval(() => {
      this.queryInterval()
    }, 3000)
  }

  beforeDestroy(): void{
    this.loading = false;
    clearInterval(this.updateInterval)
  }

  queryInterval(): void {
    this.query()
  }

  query(): void {
    if (!this.loading) {
      this.loading = true
      this.machines?.forEach(value => {
        if (value.id != undefined) {
          axiosAuth.get(`/api/v1/machinecurrentevent/machineid/${value.id}`).then((response: AxiosResponse<CurrentEvent>) => {
            // retroactively enrich machine record with current event
            // response.data.remainingTime = Math.round(Math.random()*90)
            // response.data.ncProgram = response.data.ncProgram+"-"+Math.round(Math.random()*90)
            value.currentEvent = response.data
            this.loading = false
            this.queryKey++
            console.log(response.data)
          }).catch((error) => {
            this.loading = false
            console.log(error)
          })
        }
        else
        {
          this.loading = false
        }
      })
      this.loading = false
    }
  }

  imageSource(item: Machine): string {
    if (item.icon)
      return Vue.prototype.$backendUrl + '/images/machines/' + item.icon
    else
      return '/img/machineicon_placeholder.png'
  }

  goto(machine: string): void {
    this.$router.push({name: 'MachineInfo', params: {machine: machine}})
  }

  progress(item: Machine): number {
    return 100 - (100 / item.currentEvent.estimatedTime * item.currentEvent.remainingTime)
  }

  statusColor(item: Machine): string {
    return item.currentEvent.state.colorCode
  }
}

