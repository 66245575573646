<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <page-title title-key="esab.general.debug"/>

          <v-card class="mb-5">
            <v-row>
              <v-col>
            <v-card-title>Version-Infos</v-card-title>
            <v-card-text>
              <pre>{{ debugInfosDataApp }}</pre>
              <pre>{{ debugInfosDataBackend }}</pre>
              <pre>{{ debugInfosDataFrontend }}</pre>
            </v-card-text>
              </v-col>
              <v-col>
                <v-card-title>Setting-Infos</v-card-title>
                <v-card-text>
                  <pre>{{ debugInfosDataSetting }}</pre>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        <v-card>
          <v-card-title>Cloud Registration</v-card-title>
          <v-card-text v-if="provData">
            <v-textarea v-model="provStr" label="Provisioning Config" height="200" filled/>
            <v-row>
              <v-col>
                <v-text-field v-if="provData.gateway" v-model="provData.gateway.name" label="Gateway Name"/>
              </v-col>
              <v-col>
                <v-text-field v-if="provData.cloud" v-model="provData.cloud.azure.device_id" label="Gateway ID"/>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn @click="exec('provisioning','upload',provStr)" outlined>Submit</v-btn>
            <v-btn @click="exec('provisioning','register')" outlined>Register</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card class="mb-5">
          <v-card-title>Debug</v-card-title>
          <v-card-actions>
            <v-btn @click="exec('edge','pushAllData')" outlined>Push All Data</v-btn>
            <v-btn @click="exec('edge','pushAllFiles')" outlined>Push All Files</v-btn>
            <v-btn @click="exec('edge','heartbeat')" outlined>Send Heartbeat</v-btn>
            <v-btn @click="exec('edge','echo/auth')" outlined>Echo Auth</v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="mb-5">
          <v-card-title>Edge Connector</v-card-title>
          <v-card-text>
            <template v-if="status.connected">
              <v-row dense>
                <v-col class="shrink">
                  <v-icon color="green" class="px-2">mdi-check-circle</v-icon>
                </v-col>
                <v-col>
                      <span class="subtitle-1">Connected as<code>{{
                          status.gatewayId
                        }}</code> to <code>{{ status.hostName }}</code></span>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <v-row dense>
                <v-col class="shrink">
                  <v-icon color="red" class="px-2">mdi-close-circle</v-icon>
                </v-col>
                <v-col>
                  <span class="subtitle-1">Offline</span>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="execAndUpdate('start')" outlined>Start</v-btn>
            <v-btn @click="execAndUpdate('stop')" outlined>Stop</v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="mb-5">
          <v-card-title>Messages</v-card-title>
          <v-card-text>
            <h4 v-for="(m,i) in messages" :key="i">{{ m }}</h4>
          </v-card-text>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="mb-5">
          <v-card-title>API-Key</v-card-title>
          <v-card-text>
            <pre>{{ apiKey }}</pre>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn @click="getApiKey" outlined>Refresh</v-btn>
            <v-btn @click="exec('edge','apiKeyRequest')" outlined>Request</v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="mb-5">
          <v-card-title>Azure Token</v-card-title>
          <v-card-text>
            <pre>{{ azureToken }}</pre>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn @click="getAzureToken" outlined>Get</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {axiosAuth} from '@/http-common'
import PageTitle from '@/components/PageTitle'
import Vue from 'vue'
import store from "@/store";

export default Vue.extend({
  name: 'edge',
  components: {PageTitle},
  messagePullInterval: null,
  data() {
    return {
      apiKey: '',
      azureToken: '',
      messages: [],
      status: {},
      provStr: '',
      provData: null,
      debugInfosDataApp: {},
      debugInfosDataBackend: {},
      debugInfosDataFrontend: {},
      debugInfosDataSetting: {}
    }
  },
  mounted() {
    this.updateStatus()
    this.getDebugInfos()
    this.getApiKey()
    this.getProvisioningData()
    this.messagePullInterval = setInterval(() => {
      if (this.status?.connected)
        this.pull()
    }, 500)
  },
  beforeDestroy() {
    clearInterval(this.messagePullInterval)
  },
  watch: {
    provStr() {
      this.provData = JSON.parse(this.provStr)
    }
  },
  methods: {
    getDebugInfos() {
      let res = 'empty'
      let username = localStorage.getItem('auth.user')
      console.log("setting for user: "+username)
      axiosAuth.get(`/api/v1/common/infos/`).then((response) => {
        res = response.data
        this.debugInfosDataApp.name = "CutCloud-Gateway"
        this.debugInfosDataApp.version = res.version
        this.debugInfosDataFrontend.name = "CutCloud-Gateway-Frontend"
        this.debugInfosDataFrontend.version = "3.1.2.225"
        this.debugInfosDataBackend = res
      }).catch((error) => {
        console.error(error)
      })
      axiosAuth.get(`/api/v1/settings/debug/infos/`+username).then((response) => {
        res = response.data
        this.debugInfosDataSetting = res
      }).catch((error) => {
        console.error(error)
      })
    },
    pull() {
      axiosAuth.get(`/api/v1/edge/pull-messages`).then((response) => {
        this.messages.push(...response.data)
      }).catch((error) => {
        console.error(error)
      })
    },
    getApiKey() {
      let res = 'empty'
      axiosAuth.get(`/api/v1/edge/apiKey`).then((response) => {
        res = response.data
        this.apiKey = JSON.parse(atob(response.data.split('.')[1]))
      }).catch((error) => {
        console.error(res)
        console.error(error)
      })
    },
    getAzureToken() {
      let res = 'empty'
      axiosAuth.get(`/api/v1/edge/token`).then((response) => {
        res = response.data
        this.azureToken = JSON.parse(atob(response.data.split('.')[1]))
      }).catch((error) => {
        console.error(res)
        console.error(error)
      })
    },
    getProvisioningData() {
      axiosAuth.get(`/api/v1/provisioning/data`).then((response) => {
        this.provStr = JSON.stringify(response.data)
      }).catch((error) => {
        console.log(error)
      })
    },
    execAndUpdate(command, data) {
      axiosAuth.post(`/api/v1/edge/${command}`, data).then(() => {
        this.updateStatus()
      }).catch((error) => {
        console.log(error)
      })
    },
    exec(endpoint, command, data) {
      axiosAuth.post(`/api/v1/${endpoint}/${command}`, data).then((response) => {
        console.log(response.data)
      }).catch((error) => {
        console.log(error)
      })
    },
    updateStatus() {
      axiosAuth.get(`/api/v1/edge/status`).then((response) => {
        this.status = response.data
        console.log(response.data)
      }).catch((error) => {
        console.log(error)
      })
    }
  }
})
</script>
