
import Vue from 'vue'
import ImagePreviewUpload from '@/components/ImagePreviewUpload.vue'
import {axiosAuth} from '@/http-common'
import {MachineGroup} from '@/model/machinegroup'
import {Machine} from '@/model/machine'
import {Component, Watch} from "vue-property-decorator";
import {AxiosError, AxiosResponse} from "axios";

interface NewMachineGroup {
  name: string
  scale: number
  extension: string
  machines: string[]
}

@Component({
  components: {ImagePreviewUpload}
})
export default class ManageMachineGroups extends Vue {

  valid = false
  groupImage!: File
  newGroupName = ''
  selected: Machine[] = []
  machines: Machine[] = []
  groups: MachineGroup[] = []
  search = ''
  machinesLoading = false
  groupsLoading = false

  mounted(): void {
    this.queryMachines()
    this.queryGroups()
  }

  validate(): void {
    this.valid = (
        (this.selected.length > 0) && !this.groups.map(g => g.name).includes(this.newGroupName) &&
        (this.newGroupName.length > 2)) && this.groupImage?.name != ''
  }

  queryMachines(): void {
    this.machinesLoading = true
    axiosAuth.get(`/api/v1/machines`).then((response: AxiosResponse) => {
      this.machines = response.data
      this.machinesLoading = false
    }).catch((error: AxiosError) => {
      console.log(error)
    })
  }

  queryGroups(): void {
    this.groupsLoading = true
    axiosAuth.get(`/api/v1/machinegroup`).then((response: AxiosResponse) => {
      this.groups = response.data
      this.groupsLoading = false
      this.$emit('update:reload', true)
    }).catch((error: AxiosError) => {
      console.log(error)
    })
  }

  createGroup(): void {
    let ext = ''
    if (this.groupImage != undefined) {
      ext =  this.groupImage.name.substring(this.groupImage.name.lastIndexOf('.'))
    }
    let rec: NewMachineGroup = {
      name: this.newGroupName,
      scale: 1,
      // the backend will populate the floorplan field with the records id so only the extension is stored here.
      // having the backend population the floorplan field saves us from having another client / server roundtrip.
      extension: ext,
      machines: this.selected.map(m => m.name)
    }
    axiosAuth.post(`/api/v1/machinegroup`, rec).then((response: AxiosResponse) => {
      let gropuId = response.data.id
      if (gropuId) {
        console.log(`new group ${gropuId} created`)
        this.uploadGroupImage(gropuId)
        this.queryGroups()

        this.newGroupName = ''
        this.selected = []
      }
    }).catch((error: AxiosError) => {
      console.log(error)
    })
  }

  deleteGroup(group: MachineGroup): void {
    axiosAuth.delete(`/api/v1/machinegroup/${group.id}`).then(() => {
      this.queryGroups()
    }).catch((error: AxiosError) => {
      console.log(error)
    })
  }

  uploadGroupImage(groupId: number): void {
    if (!this.groupImage) {
      console.error('background not set')
      return
    }
    let bodyFormData = new FormData()
    bodyFormData.append(groupId.toString(), this.groupImage)
    axiosAuth.post(`/api/v1/machinegroupimage`, bodyFormData, {
      headers: {'Content-Type': 'multipart/form-data'}
    }).then((response: AxiosResponse) => {
      console.log(response)
    }).catch((error: AxiosError) => {
      console.log(error)
    })
  }

  get machineHeaders() {
    return [
      {text: this.$t('esab.cutting.machines.machines'), value: 'name'}
    ]
  }

  get groupHeaders() {
    return [
      {text: this.$t('esab.cutting.machineGroups.machineGroups'), value: 'name'},
      {text: this.$t('esab.general.actions'), value: 'actions', width: 80, sortable: false, align: 'right'}
    ]
  }

  @Watch('newGroupName')
  onNewGroupNameChanged(): void {
    this.validate()
  }

  @Watch('selected')
  onSelectedChanged(): void {
    this.validate()
  }

  @Watch('groupImage')
  onGroupImageChanged(): void {
    this.validate()
  }
}
