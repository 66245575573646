
import Vue from "vue";
import {Component, Prop} from 'vue-property-decorator'

@Component
export default class InfoLine extends Vue {

  @Prop({required: true})
  name!: string

  @Prop({required: false, default: undefined})
  value!: string

  @Prop({required: false, default: 18})
  size!: number

  @Prop({required: false, default: undefined})
  link!: string
}
