
import Vue from 'vue'
import {axiosAuth} from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import PageTitle from "@/components/PageTitle.vue";
import {MachineGroup} from "@/model/machinegroup";
import MachinesPane from "@/components/MachinesPane.vue";
import ManageMachineGroups from "@/components/ManageMachineGroups.vue";
import PdmData from "@/components/PdmData.vue";
import Events from "@/components/Events.vue";
import MachinePositionPane from "@/components/MachinePositionPane.vue";
import {isAdmin} from "@/assets/auth_utils";
import {Machine} from "@/model/machine";
import {CurrentEvent} from "@/model/currentevent";

export default Vue.extend({
  name: 'machine-groups',
  components: {MachinePositionPane, Events, PdmData, ManageMachineGroups, MachinesPane, PageTitle},
  data() {
    return {
      tab: 'machines',
      updateInterval: window.setTimeout(function () {console.log("init Interval")},0),
      tabs: ['esab.cutting.machines.machines', 'esab.cutting.machines.locations', 'esab.cutting.pdmData.pdmData', 'esab.cutting.events.events'],
      machineGroupName: undefined as string | undefined,
      machineGroupNames: [] as string[],
      machineGroupPos: undefined as MachineGroup | undefined,
      machineGroupPane: undefined as MachineGroup | undefined,
      machineGroups: [] as MachineGroup[],
      search: '',
      loading: false,
      isAdmin: false,
      showDialog: false,
      selectedMachinesOverGroup: [] as Machine[]
    }
  },
  mounted() {
    this.loading = false
    this.query()
    // clearInterval(this.updateInterval);
    // this.updateInterval = window.setInterval(() => {
    //     this.queryInterval()
    //   },2500);
    this.isAdmin = isAdmin()
    if (process.env.NODE_ENV != 'production')
      this.tabs.push('raw')
  },
  beforeRouteLeave: function(to, from, next){
    this.loading = false;
    //clearInterval(this.updateInterval);
    next();
  },
  methods: {
    query() {
      if (!this.loading) {
        const group = this.$route.params.group
        this.loading = true
        axiosAuth.get(`/api/v1/machinegroup`).then((response: AxiosResponse<MachineGroup[]>) => {
          this.machineGroups = response.data
          this.machineGroupNames = this.machineGroups.map(m => m.name)
          this.machineGroupPos = this.machineGroups.find(g => g.name === group)
          this.machineGroupPane = this.machineGroupPos;
          this.machineGroupName = this.machineGroupPos?.name

          if ((this.machineGroupName == undefined)  && (this.machineGroupNames.length > 0)) {
            this.machineGroupName = this.machineGroupNames[0]
          }
          if (this.machineGroupPane == undefined){
            this.loading = false
          }else {
            this.queryCurrentEvent()
          }
        }).catch((error: AxiosError) => {
          console.log(error)
          this.loading = false
        })
        //this.loading = false;
      }
    },
    queryCurrentEvent(){
      this.selectedMachinesOverGroup = []
      this.machineGroupPane?.machineGroupPositions.forEach(mgp => {
            axiosAuth.get(`/api/v1/machinecurrentevent/machineid/${mgp.machine.id}`).then((response: AxiosResponse<CurrentEvent>) => {
              // retroactively enrich machine record with current event
              mgp.machine.currentEvent = response.data
              this.selectedMachinesOverGroup.push(mgp.machine)
              //console.log(response.data)
              this.loading = false
            }).catch((error) => {
              console.log(error)
              this.loading = false
            })
          }
      )
    },
    queryInterval() {
      if (!this.loading) {
        const group = this.$route.params.group
        if (group == undefined)
          return
        this.loading = true
        this.queryCurrentEvent()
      }
    }
  },
  watch: {
    machineGroupName: function (value: string) {
      if (this.$route.params.group !== value) {
        this.$router.push({name: 'MachineGroups', params: {group: value}})
        this.query()
      }
    }
  }
})
