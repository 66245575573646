
import Vue from 'vue'
import {Component, Watch} from 'vue-property-decorator'
import PageTitle from '@/components/PageTitle.vue'
import {axiosAuth} from '@/http-common'
import {isAdmin} from "@/assets/auth_utils"
import {AxiosError, AxiosResponse} from 'axios'

// import {isAdmin} from "@/assets/auth_utils";

interface LicenseInfoResponse {
  license: License
  valid: boolean
  invalidReason: string
  gatewayId:string
}

interface License {
  applicationName: string
  ownerName: string
  hardwareName: string
  hardwareId: string
  validFrom: Date
  validUntil: Date
  featureCode: string
  createdAt: Date
}

@Component({
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     if (!isAdmin()) {
  //       vm.$router.push({name: 'Home'})
  //     }
  //   })
  // },
  components: {PageTitle}
})
export default class Contact extends Vue {

  licenseInfo = {} as LicenseInfoResponse | undefined
  files: File[] = []
  newLicenseToken = ''
  hardwareId = ''
  isAdmin = false
  missing = false

  mounted(): void {
    this.licenseInfo = undefined
    this.isAdmin = isAdmin()
    this.updateAll()
  }

  updateAll(): void {
    this.getLicenseInfo()
    this.getHardwareId()
    this.isAdmin = isAdmin()
  }

  // @Watch('licenseInfo')
  // onLicenseChanged(value: LicenseInfoResponse): void {
  //   this.missing = value.license.application?.length > 0
  // }

  newLicense(): void {
    return
  }

  uploadLicense(): void {
    axiosAuth.put(`/api/v1/license`, this.newLicenseToken).then((response: AxiosResponse<LicenseInfoResponse>) => {
      console.log(response.data)
      this.updateAll()
    }).catch((error: AxiosError) => {
      console.log(error.code)
    })
  }

  reloadLicense(): void {
    axiosAuth.post(`/api/v1/license`).then((response: AxiosResponse<LicenseInfoResponse>) => {
      console.log(response.data)
      this.updateAll()
    }).catch((error: AxiosError) => {
      console.log(error.code)
    })
  }

  getLicenseInfo(): void {
    axiosAuth.get(`/api/v1/license/infos`).then((response: AxiosResponse<LicenseInfoResponse>) => {
      this.licenseInfo = response.data
    }).catch((error: AxiosError) => {
      console.log(error.code)
    })
  }

  getHardwareId(): void {
    axiosAuth.get(`/api/v1/license/hardwareId`).then((response: AxiosResponse<string>) => {
      this.hardwareId = response.data
    }).catch((error: AxiosError) => {
      console.log(error.code)
    })
  }

  badgeColor(): string {
    if (this.licenseInfo?.valid)
      return 'green lighten-4'
    else
      return 'red lighten-4'
  }

  addFile(event: any): void {
    let droppedFiles = event.dataTransfer.files
    if (!droppedFiles)
      return;
    ([...droppedFiles]).forEach(f => {
      this.files.push(f)
      this.uploadDocuments()
    })
  }

  uploadDocuments(): void {
    let bodyFormData = new FormData()
    bodyFormData.append('provisioning', this.files[0])

    axiosAuth.post(`/api/v1/license`, bodyFormData, {
      headers: {'Content-Type': 'multipart/form-data'}
    }).then((response: AxiosResponse) => {
      this.reloadLicense()
      console.log(response.data)
      window.location.reload()
      window.location.reload()
    }).catch((error: AxiosError) => {
      console.log(error)
    })
  }

}

