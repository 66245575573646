
import {ValidationMixin} from "@/assets/vaildation_mixin";
import {Component} from "vue-property-decorator";
import {AxiosError, AxiosResponse} from "axios";
import {axiosAuth} from '@/http-common';
import {
  userFormatDate,
  userTranslate,
  userTranslateListWithPrefix,
  userTranslateWithPrefix
} from "@/assets/date_utils";
import PageTitle from "@/components/PageTitle.vue";
import {
  getLanguageByLanguageCode,
  isAdmin
} from "@/assets/auth_utils";
import {Language} from "@/model/language";
import VueI18n from "vue-i18n";
import LocaleMessageObject = VueI18n.LocaleMessageObject;
import store from "@/store";
import {Machine} from "@/model/machine";
import {Person} from "@/model/person";

interface NewPerson {
  firstName: string
  lastName: string
  name: string
  username: string
  password: string
  passwordPin: string
  email: string
  metric: boolean
  active: boolean
  timezone: string
  language: string
  setting: string
  roles: string
  //roles: number
}

@Component({
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!isAdmin()) {
        vm.$router.push({name: 'Home'})
      }
    })
  },
  components: {PageTitle}
})
export default class UserManagement extends ValidationMixin {

  tab = 'user'
  tabs = ['esab.general.user', 'esab.cutting.users.userRole', 'esab.cutting.users.userPassword', 'esab.cutting.users.userPasswordPin']

  items: Person[] = []

  selectableUserRolls: string[] = ['CutCloudGatewayAdmin', 'CutCloudGatewayUser', 'CuttingMachineOperator', 'ColumbusUser']
  selectedUserRoll = 'CutCloudGatewayUser'

  selectableUserRollsTranslated: string[] = []
  selectedUserRollTranslated = ''

  usernameIntern = ''
  lastName = ''
  firstName = ''
  name = ''
  email = ''
  password = ''
  password1 = ''
  roles = ''
  active = true

  showPwd = false
  showPwd1 = false
  valid = false

  showOldPwd = false
  showNewPwd1 = false
  showNewPwd2 = false
  passwordOld = ''
  passwordNew1 = ''
  passwordNew2 = ''

  showOldPwdPin = false
  showNewPwdPin1 = false
  showNewPwdPin2 = false
  passwordPinOld = ''
  passwordPinNew1 = ''
  passwordPinNew2 = ''


  showAlert = false
  showDialogUserCreate = false
  showDialogUserEdit = false
  showDialogUserDeleteConfirm = false

  setting = ''

  message = ''
  messageVisible = false

  errorMessage = ''
  error = false

  selectedUser = ''

  loading = false

  metric = true
  timezone = 'Local'
  language!: Language

  timezones = [] as string[]
  languages = [] as Language[]

  userRollCCGAdmin = false
  userRollCCGUser = false
  userRollCuttingMachineOperator = false
  userRollColumbusUser = false
  deleted = false

  headers = [
    {text: 'esab.cutting.users.userName', value: 'username'},
    {text: 'esab.cutting.users.userFullname', value: 'name'},
    {text: 'esab.cutting.users.userEmail', value: 'email'},
    {
      text: 'esab.cutting.users.userRole',
      value: 'roles',
      formatter: userTranslateListWithPrefix,
      param: {prefix: 'esab.general.personnel.roles.', convertToFirstLowercase: true}
    },
    {text: 'esab.cutting.users.userUnitMetric', value: 'metric'},
    {text: 'esab.general.timezone', value: 'timezone'},
    {text: 'esab.general.language', value: 'languageAsLanguageObj.name', formatter: userTranslate},
    // {text: 'esab.cutCloud.setting', value: 'setting'},
    // {text: 'esab.cutCloud.active', value: 'active'},
    // {text: 'esab.cutting.gateways.gatewayId', value: 'gateway'},
    {text: 'esab.general.active', value: 'active'},
    {text: 'esab.cutting.users.userLastLogin', value: 'lastLogin', formatter: userFormatDate},
    {text: 'esab.cutting.storageLocations.storageLocation', value: 'storageLocationName'},
    {text: 'esab.general.created', value: 'created', formatter: userFormatDate},
    // {text: 'esab.cutting.users.userDeleted', value: 'deleted', formatter: userFormatDate},

    {text: 'esab.general.actions', value: 'actions', width: 80, sortable: false, align: 'right'}
  ]

  mounted(): void {
    this.query()
  }

  query(): void {
    this.loading = true
    axiosAuth.get(`/api/v1/common/timezones`).then((response: AxiosResponse<string[]>) => {
      this.timezones = response.data
      this.loading = false

      this.loading = true
      axiosAuth.get(`/api/v1/common/languages`).then((response: AxiosResponse<Language[]>) => {
        this.languages = response.data
        this.languages.forEach(lng => {
          lng.nameLanguageToken = "" + this.$t(lng.nameLanguageToken)
        })
        this.language = this.languages[0]
        this.loading = false

        this.loading = true
        axiosAuth.get(`/api/v1/persons`).then((response: AxiosResponse<Person[]>) => {
          this.items = response.data
          this.items.forEach(u => {
            u.languageAsLanguageObj = getLanguageByLanguageCode(u.language, this.languages)
            let gwId = (u.gatewayId != undefined) ? ' '+u.gatewayId : ''
            u.storageLocationName = (u.storageLocation.toString() == '00000000-0000-0000-0000-000000000002') ? userTranslate('esab.cutting.storageLocations.gateway') : userTranslate('esab.cutting.storageLocations.allGateways')
          })
          this.loading = false
        }).catch((error: AxiosError) => {
          console.log(error.message)
          this.loading = false
        })

      }).catch((error: AxiosError) => {
        console.log(error.message)
        this.loading = false
      })

    }).catch((error: AxiosError) => {
      console.log(error.message)
      this.loading = false
    })
  }

  inputUserCreate(): void {
    this.usernameIntern = 'User' + Math.round(Math.random() * 10000000)
    this.name = ''
    this.firstName = ''
    this.lastName = ''
    this.email = ''
    this.password = 'Pass' + Math.round(Math.random() * 10000000)
    this.timezone = 'Local'
    this.language = this.languages[0]
    this.metric = true
    this.roles = ''
    this.active = true
    this.deleted = false
    this.selectableUserRollsTranslated = []
    this.selectableUserRolls.forEach(r => {
      this.selectableUserRollsTranslated.push(userTranslateListWithPrefix(r, {
        prefix: 'esab.general.personnel.roles.',
        convertToFirstLowercase: true
      }))
    })
    this.selectedUserRollTranslated = userTranslateListWithPrefix(this.selectedUserRoll, {
      prefix: 'esab.general.personnel.roles.',
      convertToFirstLowercase: true
    })
    this.selectedUser = ''
    this.showAlert = false
    this.showDialogUserCreate = true
  }

  createUser(): void {

    console.log("create user " + this.usernameIntern)
    let selRole = 'None'

    this.showAlert = false;

    this.selectableUserRolls.forEach(r => {
      if (this.selectedUserRollTranslated == userTranslateListWithPrefix(r, {
        prefix: 'esab.general.personnel.roles.',
        convertToFirstLowercase: true
      })) {
        selRole = r
      }
    })

    this.roles = selRole

    const newPerson: NewPerson = {
      firstName: this.firstName,
      lastName: this.lastName,
      name: (this.firstName + ' ' + this.lastName).trimStart().trimEnd(),
      username: this.usernameIntern,
      password: this.password,
      passwordPin: this.password,
      metric: this.metric,
      active: this.active,
      language: this.language.code,
      timezone: this.timezone,
      setting: this.setting,
      email: this.email,
      roles: this.roles
    }

    if (newPerson.language.length < 1)
      newPerson.language = 'Local'

    if (this.password != this.password1) {
      this.errorMessage = "esab.cutting.passwords.notEqualPasswords"
      this.showAlert = true;
    } else {
      axiosAuth.post(`/api/v1/persons`, newPerson).then((response: AxiosResponse<boolean>) => {
        console.log(response.data)
        this.query()
        this.message = this.$t('esab.general.created', {name: newPerson.username}).toString()
        this.messageVisible = true
        this.error = false
      }).catch((error: AxiosError) => {
        console.log(error.message)
        this.errorMessage = this.$t('esab.general.errorMessage', {code: error.code}).toString()
        this.error = true
      })
      this.showDialogUserCreate = false
    }
  }

  inputUserEdit(user: Person): void {
    this.usernameIntern = user.username
    this.name = user.name
    this.firstName = (user.firstName != null) ? user.firstName : ''
    this.lastName = (user.lastName != null) ? user.lastName : ''
    this.email = user.email
    this.language = user.languageAsLanguageObj
    this.metric = user.metric
    this.timezone = user.timezone
    this.setting = user.setting
    this.password = user.password
    this.roles = user.roles.replace(' ,', ',').replace(', ', ',').replace(',,', ',')
    this.selectedUserRoll = user.roles
    this.active = user.active
    this.deleted = (user.deleted != undefined)

    this.password = ''
    this.password1 = ''
    this.passwordOld = ''
    this.passwordNew1 = ''
    this.passwordNew2 = ''
    this.passwordPinOld = ''
    this.passwordPinNew1 = ''
    this.passwordPinNew2 = ''

    const uRoles = this.roles.split(',')

    this.userRollCCGAdmin = uRoles.includes('CutCloudGatewayAdmin')
    this.userRollCCGUser = uRoles.includes('CutCloudGatewayUser')
    this.userRollCuttingMachineOperator = uRoles.includes('CuttingMachineOperator')
    this.userRollColumbusUser = uRoles.includes('ColumbusUser')

    this.showAlert = false
    this.showDialogUserEdit = true
  }

  editUser(): void {
    console.log("update user " + this.usernameIntern)
    console.log("active user " + localStorage.getItem("auth.user"))

    this.showAlert = false;

    let reqBody = {
      oldPassword: this.passwordOld,
      newPassword: this.passwordNew1,
      newPasswordPin: this.passwordPinNew1
    }
    if ((this.passwordPinOld != undefined && this.passwordPinOld.length > 0)) {
      reqBody.oldPassword = this.passwordPinOld
    }

    if (this.passwordNew1 != this.passwordNew2) {
      this.errorMessage = "esab.cutting.passwords.notEqualPasswords"
      this.showAlert = true;
    }
    if (this.passwordPinNew1 != this.passwordPinNew2) {
      this.errorMessage = "esab.cutting.passwords.notEqualPasswordsPins"
      this.showAlert = true;
    }

    // if (reqBody.oldPassword == '' && (reqBody.newPassword + reqBody.newPasswordPin != '')) {
    //   this.errorMessage = "esab.cutting.passwords.needOldPassword"
    //   this.showAlert = true;
    // }

    if (!this.showAlert) {
      axiosAuth.post(`/api/v1/persons/usernames/${this.usernameIntern}/changepassword?type=admin`, reqBody).then((response: AxiosResponse<boolean>) => {

        if ((!response.data) && (reqBody.newPassword + reqBody.newPasswordPin != '')) {
          this.errorMessage = this.$t('esab.cutting.passwords.passwordChangeFailed') + " : change password/pin (current password?)"
          this.showAlert = true;
        }

        if (!this.showAlert) {
          let editActiveUser = (this.usernameIntern === localStorage.getItem("auth.user"))

          const newPerson: NewPerson = {
            firstName: this.firstName,
            lastName: this.lastName,
            name: (this.firstName + ' ' + this.lastName).trimStart().trimEnd(),
            username: this.usernameIntern,
            password: this.password,
            passwordPin: this.passwordPinNew1,
            active: this.active,
            metric: this.metric,
            language: this.language.code,
            timezone: this.timezone,
            setting: this.setting,
            email: this.email,
            roles: this.selectedUserRoll
          }

          newPerson.roles = this.roles

          newPerson.roles = this.personRolesUtil(newPerson.roles, 'CutCloudGatewayAdmin', this.userRollCCGAdmin)
          newPerson.roles = this.personRolesUtil(newPerson.roles, 'CutCloudGatewayUser', this.userRollCCGUser)
          newPerson.roles = this.personRolesUtil(newPerson.roles, 'CuttingMachineOperator', this.userRollCuttingMachineOperator)
          newPerson.roles = this.personRolesUtil(newPerson.roles, 'ColumbusUser', this.userRollColumbusUser)

          if (newPerson.language.length < 1)
            newPerson.language = 'Local'

          axiosAuth.put(`/api/v1/persons/usernames/${this.usernameIntern}/update`, newPerson).then((response: AxiosResponse<boolean>) => {
            console.log(response.data)
            this.query()
            this.message = this.$t('esab.general.update', {name: newPerson.username}).toString()
            this.messageVisible = true
            this.error = false

            if (editActiveUser) {
              axiosAuth.put(`/api/v1/persons/usernames/${this.usernameIntern}/update`, newPerson).then((response: AxiosResponse<boolean>) => {
                console.log("save user profile: " + response.data)
                window.location.reload()
                this.$emit('update:close', true)
              }).catch((error: AxiosError) => {
                console.log(error.message)
              })
            }
          }).catch((error: AxiosError) => {
            console.log(error.message)
            this.errorMessage = this.$t('esab.general.errorMessage', {code: error.code}).toString()
            this.error = true
          })

          this.showDialogUserEdit = false
        }


      }).catch((error: AxiosError) => {
        console.log(error)
      })
    }
  }

  personRolesUtil(sourceRoles: string, keyword: string, doInsert: boolean): string {
    const lst = sourceRoles.split(',')
    let res = ''
    lst.forEach(ur => {
      if (ur.localeCompare(keyword) != 0) {
        if (res.length > 0)
          res = res + ','
        res = res + ur
      }
    })
    if (doInsert) {
      if (res.length > 0)
        res = res + ','
      res = res + keyword
    }
    return res
  }

  askDeleteUser(user: Person): void {
    this.selectedUser = user.username
    this.showDialogUserDeleteConfirm = true
  }

  deleteUser(): void {
    console.log("delete user " + this.selectedUser)

    axiosAuth.delete(`/api/v1/persons/usernames/${this.selectedUser}`).then((response: AxiosResponse<boolean>) => {
      console.log(response.data)
      this.query()
      this.message = this.$t('esab.general.delete', {name: this.selectedUser}).toString()
      this.messageVisible = true
      this.error = false
    }).catch((error: AxiosError) => {
      console.log(error.message)
      this.errorMessage = this.$t('esab.general.errorMessage', {code: error.code}).toString()
      this.error = true
    })

    this.showDialogUserDeleteConfirm = false
  }
}
