import { render, staticRenderFns } from "./document-management.vue?vue&type=template&id=be4bad6c&scoped=true&"
import script from "./document-management.vue?vue&type=script&lang=ts&"
export * from "./document-management.vue?vue&type=script&lang=ts&"
import style0 from "./document-management.vue?vue&type=style&index=0&id=be4bad6c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be4bad6c",
  null
  
)

export default component.exports