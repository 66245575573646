<template>
  <v-layout>
    <v-flex lg12>
      <gti-app-bar>
        <template v-slot:leftactions>
          <v-flex shrink>
            <v-btn text @click="savePositions" v-if="isAdmin">
              <v-icon left>mdi-content-save</v-icon>
              {{ $t('esab.cutting.machineGroups.saveMachineGroupPositions') }}
            </v-btn>
          </v-flex>
        </template>
      </gti-app-bar>
      <v-flex lg12 ref="container">
        <v-stage ref="stage" :config="stageSize">
          <v-layer ref="bg">
            <v-image :config="{image: backgroundImage}"/>
          </v-layer>
          <v-layer ref="machines"/>
          <v-layer ref="tooltip"/>
        </v-stage>
      </v-flex>
    </v-flex>
    <gti-message v-model="messageVisible" :message="message"/>
  </v-layout>
</template>

<script>
import Konva from 'konva'
import {axiosAuth} from '@/http-common'
import {isAdmin} from '@/assets/auth_utils'
import {MachineGroupPosition} from '@/model/machinegroupposition'
import Vue from "vue";

const width = window.innerWidth
const height = window.innerHeight

export default {
  name: 'MachinePositionPane',
  props: [
    'machineGroup'
  ],
  data() {
    return {
      stageSize: {
        width: width,
        height: height
      },
      isAdmin: false,
      maxWidth: 0,
      message: '',
      messageVisible: false,
      stage: null,
      tooltip: null,
      layerMachines: null,
      layerTooltip: null,
      backgroundImage: null,
      positionUpdates: []
    }
  },
  mounted() {
    this.isAdmin = isAdmin()
    this.maxWidth = this.$refs.container.clientWidth - 32
    this.stage = this.$refs.stage.getNode()
    this.layerMachines = this.$refs.machines.getNode()
    this.layerTooltip = this.$refs.tooltip.getNode()

    this.initBackground(this.machineGroup.image)

    this.tooltip = new Konva.Group({
      visible: false
    })

    let box = new Konva.Rect({
      width: 200,
      height: 30,
      fill: 'white',
      stroke: '#333',
      strokeWidth: 2,
      opacity: 0.8,
      cornerRadius: 5
    })

    let text = new Konva.Text({
      id: 'text',
      text: 'here be dragons',
      fontFamily: 'Arial',
      fontSize: 20,
      padding: 5,
      fill: '#333'
    })

    this.tooltip.add(box)
    this.tooltip.add(text)

    this.layerTooltip.add(this.tooltip)

    this.updateMachines(this.machineGroup.machineGroupPositions)
  },
  methods: {
    initBackground(image) {
      console.log('init plan image ' + image)
      const bg = new window.Image()
      this.backgroundImage = null
      if (image!= null && image.length > 4) {
        bg.src = this.$backendUrl + '/images/machinegroups/' + image
      }
      else {
        bg.src = '/img/machinegroupimage_placeholder.png'
      }
      bg.onload = () => {
        if (bg.width > this.maxWidth) {
          let fac = this.maxWidth / bg.width
          console.log('scaling down by ' + fac)
          bg.width = bg.width * fac
          bg.height = bg.height * fac
        }
        this.stageSize.width = bg.width
        this.stageSize.height = bg.height
        this.backgroundImage = bg
      }
    },
    addMachine(posrec) {
      console.log('add machine with id ' + posrec.id)
      let cfg = {
        id: posrec.id.toString(),
        name: posrec.machine.name,
        image: this.image,
        x: posrec.posX,
        y: posrec.posY,
        scale: posrec.scale,
        rotation: posrec.rotation,
        draggable: true,
        shadowBlur: 4
      }

      let tt = this.tooltip
      let stg = this.stage
      let updatePos = this.updatePosition

      const img = new window.Image()
      if (posrec.machine.icon)
        img.src = this.$backendUrl + '/images/machines/' + posrec.machine.icon
      else
        img.src = '/img/machineicon_placeholder.png'
      img.onload = () => {
        cfg.image = img

        let machine = new Konva.Image(cfg)

        machine.offsetX(img.width / 2)
        machine.offsetY(img.height / 2)

        machine.on('mousemove', function () {
          let mousePos = stg.getPointerPosition()
          tt.position({
            x: mousePos.x + 15,
            y: mousePos.y + 15
          })
          tt.find('#text')[0].text(cfg.name)
          tt.show()
        })

        machine.on('mouseout', function () {
          tt.hide()
        })

        machine.on('dblclick', function () {
          machine.rotate(15)
          updatePos(machine)
        })

        machine.on('dragend', function () {
          updatePos(machine)
        })

        this.layerMachines.add(machine)
      }
    },
    clearMachines() {
      console.log('clear machines')
      this.layerMachines.find(() => true).forEach(n => n.destroy())
      this.layerMachines.draw()
    },
    updateMachines(positions) {
      console.log('update machines')
      this.clearMachines()
      positions.forEach(pos => {
        this.addMachine(pos)
      })
      this.positionUpdates = positions.map(p => {
          return {id: p.id, posX: p.posX, posY: p.posY, rotation: p.rotation, scale: p.scale}
        }
      )
    },
    updatePosition(machine) {
      let posrec = this.positionUpdates.find(pu => pu.id.toString() === machine.id())
      posrec.posX = machine.x()
      posrec.posY = machine.y()
      posrec.scale = 1
      posrec.rotation = machine.rotation()
      //Warum ist machine.scale() ein Koordinate?
      // console.log(machine.name()+"#####Scale:"+posrec.scale+"######Rot:"+posrec.rotation+"#####Scale:"+machine.scale().x)
    },
    savePositions() {
      axiosAuth.put(`/api/v1/machinegroup/${this.machineGroup.id}`, this.positionUpdates).then(response => {
        this.$emit('update:reload', true)
        this.message = this.$t('esab.cutting.machineGroups.saveMachineGroupPositions')
        this.messageVisible = true
      }).catch(error => {
        console.log(error)
      })
    }
  },
  watch: {
    machineGroup(group) {
      console.log('machine group updated to id ' + group.id)
      this.initBackground(group.image)
      this.updateMachines(group.machineGroupPositions)
    }
  }
}
</script>
