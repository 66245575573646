
import {ValidationMixin} from "@/assets/vaildation_mixin";
import {Component} from "vue-property-decorator";
import {AxiosError, AxiosResponse} from "axios";
import {axiosAuth} from '@/http-common';
import {
  userFormatDate,
  userTranslate,
  userTranslateListWithPrefix,
  userTranslateWithPrefix
} from "@/assets/date_utils";
import PageTitle from "@/components/PageTitle.vue";
import {
  isAdmin
} from "@/assets/auth_utils";
import VueI18n from "vue-i18n";
import LocaleMessageObject = VueI18n.LocaleMessageObject;
import store from "@/store";
import {Machine} from "@/model/machine";
import {Person} from "@/model/person";

interface NewMachine {
  id: string
  name: string
  serialNo: string
  articleNo: string
  softwareVersion: string
  controllerVersion: string
  sdpVersion: string
  ipAddress: string
  timezone: string
  password: string
  active: boolean
}

@Component({
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!isAdmin()) {
        vm.$router.push({name: 'Home'})
      }
    })
  },
  components: {PageTitle}
})
export default class MachineManagement extends ValidationMixin {

  tab = 'machine'
  tabs = ['esab.general.machine', 'esab.cutting.machines.machinePassword']
  tabs1 = ['esab.general.machine']
  tabsEdit = ['esab.general.machine']

  items: Machine[] = []

  machineIdIntern = ''
  id = ''
  name = ''
  serialNo = ''
  articleNumber = ''
  softwareVersion = ''
  sdpVersion = ''
  controllerVersion = ''
  ipAddress = ''
  password = ''
  password1 = ''
  active = true
  hasPassword = false

  showPwd = false
  showPwd1 = false
  valid = false

  showOldPwd = false
  showNewPwd1 = false
  showNewPwd2 = false
  passwordOld = ''
  passwordNew1 = ''
  passwordNew2 = ''

  showAlert = false
  showDialogMachineCreate = false
  showDialogMachineEdit = false
  showDialogMachineDeleteConfirm = false

  deleted = false

  message = ''
  messageVisible = false

  errorMessage = ''
  error = false

  selectedMachine = ''

  loading = false

  timezone = 'Local'
  timezones = [] as string[]

  headers = [
    {text: 'esab.general.name', value: 'name'},
    {text: 'esab.general.serialNumber', value: 'serialNo'},
    {text: 'esab.general.id', value: 'id'},
    {text: 'esab.general.articleNumber', value: 'articleNo'},
    {text: 'esab.general.softwareVersion', value: 'softwareVersion'},
    {text: 'esab.cutting.machines.sdpVersion', value: 'sdpVersion'},
    {text: 'esab.cutting.machines.controllerVersion', value: 'controllerVersion'},
    {text: 'esab.cutting.machines.ipAddressInternal', value: 'ipAddress'},
    {text: 'esab.general.warrantyDate', value: 'warrantyDate', formatter: userFormatDate},
    {text: 'esab.general.active', value: 'active'},
    {text: 'esab.general.created', value: 'created', formatter: userFormatDate},
    //{text: 'esab.cutting.users.userDeleted', value: 'deleted', formatter: userFormatDate},
    {text: 'esab.general.timezone', value: 'timezone'},
    {text: 'esab.cutting.users.userPassword', value: 'hasPassword'},

    {text: 'esab.general.actions', value: 'actions', width: 80, sortable: false, align: 'right'}
  ]

  mounted(): void {
    this.query()
  }

  query(): void {
    this.loading = true
    axiosAuth.get(`/api/v1/common/timezones`).then((response: AxiosResponse<string[]>) => {
      this.timezones = response.data
      this.loading = false


      this.loading = true
      axiosAuth.get(`/api/v1/machines`).then((response: AxiosResponse<Machine[]>) => {
        this.items = response.data
        this.items.forEach(e => {
          e.hasPassword = ((e.password != undefined) && (e.password != ''))
          e.active = (e.deactivated == undefined)
        })
        this.loading = false

      }).catch((error: AxiosError) => {
        console.log(error.message)
        this.loading = false
      })

    }).catch((error: AxiosError) => {
      console.log(error.message)
      this.loading = false
    })
  }

  inputMachineCreate(): void {
    this.machineIdIntern = crypto.randomUUID()
    this.name = 'CM-'+this.machineIdIntern
    this.ipAddress = ''
    this.serialNo = ''
    this.articleNumber = ''
    this.controllerVersion = ''
    this.softwareVersion = ''
    this.sdpVersion = ''
    this.password = 'Pass' + Math.round(Math.random() * 10000000)
    this.timezone = 'UTC'
    this.active = true
    this.deleted = false

    this.selectedMachine = ''
    this.showAlert = false
    this.showDialogMachineCreate = true
  }

  createMachine(): void {

    console.log("create machine " + this.machineIdIntern)
    let selRole = 'None'

    this.showAlert = false;

    const newMachineC: NewMachine = {
      id: this.machineIdIntern,
      name: this.name,
      articleNo: this.articleNumber,
      serialNo: this.serialNo,
      sdpVersion: this.sdpVersion,
      controllerVersion: this.controllerVersion,
      softwareVersion: this.softwareVersion,
      ipAddress: this.ipAddress,
      timezone: this.timezone,
      password: this.password,
      active: this.active
    }

    if (this.password != this.password1) {
      this.errorMessage = "esab.cutting.passwords.notEqualPasswords"
      this.showAlert = true;
    } else {
      axiosAuth.post(`/api/v1/machines`, newMachineC).then((response: AxiosResponse<boolean>) => {
        console.log(response.data)
        this.query()
        this.message = this.$t('esab.general.created', {name: newMachineC.name}).toString()
        this.messageVisible = true
        this.error = false
      }).catch((error: AxiosError) => {
        console.log(error.message)
        this.errorMessage = this.$t('esab.general.errorMessage', {code: error.code}).toString()
        this.error = true
      })
      this.showDialogMachineCreate = false
    }
  }

  inputUserEdit(machine: Machine): void {

    this.machineIdIntern = machine.id
    this.name = machine.name
    this.ipAddress = machine.ipAddress
    this.serialNo = machine.serialNo
    this.articleNumber = machine.articleNo
    this.controllerVersion = machine.controllerVersion
    this.softwareVersion = machine.softwareVersion
    this.sdpVersion = machine.sdpVersion
    this.active = machine.active
    this.timezone = machine.timezone

    this.hasPassword = machine.hasPassword
    this.deleted = (machine.deleted != undefined)

    this.selectedMachine = ''

    this.password = ''
    this.password1 = ''
    this.passwordOld = ''
    this.passwordNew1 = ''
    this.passwordNew2 = ''

    this.showAlert = false
    this.showDialogMachineEdit = true

    this.tabsEdit = (this.hasPassword) ? this.tabs : this.tabs1
  }

  editMachine(): void {
    console.log("update user " + this.machineIdIntern)
    console.log("active user " + localStorage.getItem("auth.user"))

    this.showAlert = false;

    let reqBody = {
      oldPassword: this.passwordOld,
      newPassword: this.passwordNew1,
      newPasswordPin: undefined
    }

    if (this.passwordNew1 != this.passwordNew2) {
      this.errorMessage = "esab.cutting.passwords.notEqualPasswords"
      this.showAlert = true;
    }

    // if (reqBody.oldPassword == '' && (reqBody.newPassword != '')) {
    //   this.errorMessage = "esab.cutting.passwords.needOldPassword"
    //   this.showAlert = true;
    // }

    if (!this.showAlert) {
      axiosAuth.post(`/api/v1/machines/${this.machineIdIntern}/changepassword?type=admin`, reqBody).then((response: AxiosResponse<boolean>) => {

        if ((!response.data) && (reqBody.newPassword != '')) {
          this.errorMessage = this.$t('esab.cutting.passwords.passwordChangeFailed') + " : change password/pin (current password?)"
          this.showAlert = true;
        }

        if (!this.showAlert) {

          const newMachineU: NewMachine = {
            id: this.machineIdIntern,
            name: this.name,
            articleNo: this.articleNumber,
            serialNo: this.serialNo,
            sdpVersion: this.sdpVersion,
            controllerVersion: this.controllerVersion,
            softwareVersion: this.softwareVersion,
            ipAddress: this.ipAddress,
            timezone: this.timezone,
            password: this.password,
            active: this.active
          }

          axiosAuth.put(`/api/v1/machines/${this.machineIdIntern}/update`, newMachineU).then((response: AxiosResponse<boolean>) => {
            console.log(response.data)
            this.query()
            this.message = this.$t('esab.general.update', {name: newMachineU.name}).toString()
            this.messageVisible = true
            this.error = false

          }).catch((error: AxiosError) => {
            console.log(error.message)
            this.errorMessage = this.$t('esab.general.errorMessage', {code: error.code}).toString()
            this.error = true
          })

          this.showDialogMachineEdit = false
        }


      }).catch((error: AxiosError) => {
        console.log(error)
      })
    }
  }

  personRolesUtil(sourceRoles: string, keyword: string, doInsert: boolean): string {
    const lst = sourceRoles.split(',')
    let res = ''
    lst.forEach(ur => {
      if (ur.localeCompare(keyword) != 0) {
        if (res.length > 0)
          res = res + ','
        res = res + ur
      }
    })
    if (doInsert) {
      if (res.length > 0)
        res = res + ','
      res = res + keyword
    }
    return res
  }

  askDeleteMachine(mach: Machine): void {
    this.selectedMachine = mach.id
    this.showDialogMachineDeleteConfirm = true
  }

  deleteMachine(): void {
    console.log("delete machine " + this.selectedMachine)

    axiosAuth.delete(`/api/v1/machines/${this.selectedMachine}`).then((response: AxiosResponse<boolean>) => {
      console.log(response.data)
      this.query()
      this.message = this.$t('esab.general.delete', {name: this.selectedMachine}).toString()
      this.messageVisible = true
      this.error = false
    }).catch((error: AxiosError) => {
      console.log(error.message)
      this.errorMessage = this.$t('esab.general.errorMessage', {code: error.code}).toString()
      this.error = true
    })

    this.showDialogMachineDeleteConfirm = false
  }
}
