<template>
  <v-snackbar :value="data" :timeout="3000" top @input="data = $event">
    <span class="text-subtitle-1">{{ message }}</span>
    <template v-slot:action="{ attrs }">
      <v-btn color="yellow" text v-bind="attrs" @click="data = false">{{ $t('close') }}</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'GtiMessage',
  props: ['value', 'message'],
  data() {
    return {
      data: ''
    }
  },
  beforeMount() {
    this.data = this.value;
  },
  watch: {
    data() {
      this.$emit('input', this.data)
    },
    value() {
      this.data = this.value;
    }
  }
}
</script>
