<template>
  <v-layout wrap>
    <v-spacer/>
    <v-flex shrink pa-2>
      <v-btn :disabled="!image" @click="image=null">{{ $t('esab.general.clearImage') }}</v-btn>
    </v-flex>
    <v-flex lg12 pa-2>
      <div v-cloak @drop.prevent="setFile" @dragover.prevent>
        <div :hidden="image">
          <div class="upload-box pa-3 align-content-space-around justify-center" :hidden="image"
               style="height: 200px">
            {{ $t(text) }}
          </div>
        </div>
        <div :hidden="!image">
          <img id="image" style="width: 100%" class="elevation-4"/>
        </div>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>

export default {
  name: 'ImagePreviewUpload',
  props: {
    w: {
      type: Number,
      required: false,
      default: 400
    },
    h: {
      type: Number,
      required: false,
      default: 300
    },
    text: {
      type: String,
      required: false,
      default: 'upload'
    },
    // image: {
    //   type: Object,
    //   required: true,
    //   default: null
    // }
  },
  data() {
    return {
      image: null
    }
  },
  methods: {
    setFile(event) {
      this.image = event.dataTransfer.files[0]
    },
    clearFile(file) {
      this.image = null
    },
    singlePreview(file) {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function () {
        let image = document.getElementById('image')
        image.src = reader.result
        image.onload = function () {
          URL.revokeObjectURL(image.src) // free memory
        }
      }
    }
  },
  watch: {
    image(file) {
      this.$emit('update:image', file)
      if (file)
        this.singlePreview(file)
    }
  }
}
</script>
<style scoped>
.upload-box {
  border-radius: 5px;
  border: 3px dashed #888;
  background-color: #eee;
  display: flex;
  flex-wrap: wrap;
}
</style>
