

import {Component, Prop} from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class PageTitle extends Vue {
  @Prop({default: null, required: true})
  titleKey!: string
}
