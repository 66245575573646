import Vue from 'vue'
import {userFormatDate} from "@/assets/date_utils";

Vue.filter('formatDate', function (value: string) {
  if (value) {
    return userFormatDate(value);
  }
})

Vue.filter('decimals', function (value: number, params: any) {
  if (value) {
    if (params.digits == undefined)
      params.digits = 3;

    const valdigit = Math.pow(10, params.digits);

    if (params.factor == undefined)
      params.factor = 1.0;

    if (params.unitFactor == undefined)
      params.unitFactor = 1.0;

    let res = value * params.factor * params.unitFactor;
    res = Math.round(res * valdigit);
    res = res / valdigit;
    return res;
  }
})
