import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from '../views/home.vue'
import EventList from '../views/event-list.vue'
import SheetList from '../views/sheet-list.vue'
import MachineGroups from '../views/machine-groups.vue'
import MachineInfo from '../views/machine-info.vue'
import OperatorList from '../views/operator-list.vue'
import Reports from '../views/reports.vue'
import DocumentManagement from '@/views/document-management.vue'
import UserManagement from '@/views/user-management.vue'
import MachineManagement from '@/views/machine-management.vue'
import Contact from '../views/contact.vue'
import License from '../views/license.vue'
import Upload from '../views/upload.vue'
import Edge from '../views/edge.vue'
import EdgePdmDataList from '../views/edge-pdmdata-list.vue'

Vue.use(VueRouter)

const anonRoutes = ['Home', 'Contact']

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Home,
    name: 'Home'
  },
  {
    path: '/machine-groups/:group?',
    component: MachineGroups,
    name: 'MachineGroups'
  },
  {
    path: '/machine-info/:machine?',
    component: MachineInfo,
    name: 'MachineInfo'
  },
  {
    path: '/list-events',
    component: EventList
  },
  {
    path: '/list-sheets',
    component: SheetList
  },
  {
    path: '/operator-list',
    component: OperatorList
  },
  {
    path: '/reports',
    component: Reports
  },
  {
    path: '/license',
    component: License,
    name: 'License'
  },
  {
    path: '/contact',
    component: Contact,
    name: 'Contact'
  },
  {
    path: '/document-management',
    component: DocumentManagement
  },
  {
    path: '/user-management',
    component: UserManagement
  },
  {
    path: '/machine-management',
    component: MachineManagement
  },
  {
    path: '/upload',
    component: Upload
  },
  {
    path: '/edge',
    component: Edge
  }
  // {
  //   path: '/about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Contact' || to.name === 'License')
    next()
  else if (to.name !== 'Home' && !localStorage.getItem('auth.token')) next({name: 'Home'})
  else next()
})

export default router
