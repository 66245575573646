import moment from 'moment'
import vue from 'vue'
import i18n from "@/plugins/i18n";
import store from "@/store";


// export function formatDate(value: string): string {
//     if (!value)
//         return ''
//     return moment(String(value)).format('YYYY-MM-DD HH:mm')
// }

// export function formatDateShort(value: string): string {
//     if (!value)
//         return ''
//     return moment(String(value)).format('YYYY-MM-DD')
// }


// export function dynFormatDate(value: string, param: any): string {
//     if (!value)
//         return ''
//     return moment(String(value)).format(param.formatString)
// }

// export function dynFormatTimeBySeconds1(value: number, param: any): string {
//     const h = Math.trunc(value / 3600);
//     const m = Math.trunc((value - (h * 3600)) / 60);
//     const s = value - (h * 3600) - (m * 60);
//
//     const res = h.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false})+":"+m.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false})+":"+s.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false});
//     return res;
// }

export function userFormatTimeBySeconds(value: number): string {
    const h = Math.trunc(value / 3600);
    const m = Math.trunc((value - (h * 3600)) / 60);
    const s = value - (h * 3600) - (m * 60);

    const res = h.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false})+":"+m.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false})+":"+s.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false});
    return res;
}

export function userFormatDate(value: string): string {
    if (!value)
        return ''

    const dd = store.state.moment.utc(value).tz(store.state.settings.userTimezone);
    return ''+dd.format(store.state.settings.userDateFormat);
}

export function userTranslate(token: string): string {
    const res = i18n.t(token).toString();
    return res;
}

export function userTranslateWithPrefix(token: string, param: any): string {
    if (token.length < 1)
        return ''

    if (param.convertToFirstLowercase) {
        const firstLetter = token.substring(0, 1).toLowerCase()
        const restString = token.substring(1, token.length)
        token = firstLetter + restString
    }
    const res = i18n.t(param.prefix+''+token).toString();
    return res;
}

export function userTranslateListWithPrefix(token: string, param: any): string {
    const tks = token.split(',')
    let res = ''
    tks.forEach(tk => {
        tk.trimStart()
        tk.trimEnd()
        if (param.convertToFirstLowercase) {
            const firstLetter = tk.substring(0, 1).toLowerCase()
            const restString = tk.substring(1, tk.length)
            tk = firstLetter + restString
        }
        if (tk.length > 0) {
            if (res.length > 0)
                res = res + ', ';
            res = res + i18n.t(param.prefix + tk).toString()
        }
    })

    return res;
}


export function userTranslateExtent(token: string): string {
    let addToken = ''
    console.log(store.state.settings)
    if (store.state.settings != undefined && store.state.settings.translationExtensionCode != undefined)
        addToken = store.state.settings.translationExtensionCode
    const res = i18n.t(""+token+addToken).toString();
    return res;
}

export function decimals(value: number, param: any): number {
    if (!value)
        return 0

    if (param.digits == undefined)
        param.digits = 3;

    const valdigit = Math.pow(10, param.digits);

    if (param.factor == undefined)
        param.factor = 1.0;

    if (param.unitFactor == undefined)
        param.unitFactor = 1.0;

    let res = value * param.factor * param.unitFactor;
    res = Math.round(res * valdigit);
    res = res / valdigit;
    return res;
}
