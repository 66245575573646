
import Vue from 'vue'
import {Component} from 'vue-property-decorator'
import PageTitle from '@/components/PageTitle.vue'
import {axiosAuth} from '@/http-common'
import {AxiosResponse} from "axios"
import {Sheet} from "@/model/sheet"
import {decimals, userFormatDate} from "@/assets/date_utils";
import store from "@/store";

@Component({
  components: {PageTitle}
})
export default class SheetList extends Vue {

  items: Sheet[] = []
  loading = false

  headers = [
    {text: 'esab.cutting.sheets.sheetName', value: 'name'},
    {
      text: this.$t('esab.general.width') + ' ' + '[' + store.state.settings.distUnitName + ']',
      value: 'dimensionX', formatter: decimals,
      param: {digits: store.state.settings.distDigits, unitFactor: store.state.settings.distUnitFactor, factor: 1.0}
    },
    {
      text: this.$t('esab.general.count'),
      value: 'count', formatter: decimals,
      param: {digits: 0, unitFactor: 1, factor: 1.0}
    },
    {
      text: this.$t('esab.general.height') + ' ' + '[' + store.state.settings.distUnitName + ']',
      value: 'dimensionY', formatter: decimals,
      param: {digits: store.state.settings.distDigits, unitFactor: store.state.settings.distUnitFactor, factor: 1.0}
    },
    {
      text: this.$t('esab.cutting.materials.thickness') + ' ' + '[' + store.state.settings.distUnitName + ']',
      value: 'thickness', formatter: decimals,
      param: {digits: store.state.settings.distDigits, unitFactor: store.state.settings.distUnitFactor, factor: 1.0}
    },
    {
      text: this.$t('esab.general.area') + ' ' + '[' + store.state.settings.areaUnitName + ']',
      value: 'area', formatter: decimals,
      param: {digits: store.state.settings.areaDigits, unitFactor: store.state.settings.areaUnitFactor, factor: 0.000001}
    },
    {
      text: this.$t('esab.general.weight') + ' ' + '[' + store.state.settings.weightUnitName + ']',
      value: 'weight', formatter: decimals,
      param: {digits: store.state.settings.weightDigits, unitFactor: store.state.settings.weightUnitFactor, factor: 1.0}
    },
    {text: 'esab.cutting.materials.material', value: 'material'},
    {text: 'esab.cutting.materials.userNotice1', value: 'userNote1'},
    {text: 'esab.cutting.materials.userNotice2', value: 'userNote2'},
    {text: 'esab.cutting.materials.userNotice3', value: 'userNote3'},
    {text: 'esab.cutting.materials.userNotice4', value: 'userNote4'},
    {text: 'esab.cutting.materials.userCategory1', value: 'userCategory1'},
    {text: 'esab.cutting.materials.userCategory2', value: 'userCategory2'},
    {text: 'esab.cutting.materials.userCategory3', value: 'userCategory3'},
    {text: 'esab.cutting.materials.userCategory4', value: 'userCategory4'},
    {
      text: 'esab.cutting.materials.delivered',
      value: 'delivered',
      formatter: userFormatDate
    },
    {text: 'esab.cutting.materials.remnant', value: 'remnant'},
    {text: 'esab.cutting.materials.locked', value: 'locked'},
    {text: 'esab.cutting.materials.rectangular', value: 'rectangular'},
    {text: 'esab.cutting.materials.mirrorable', value: 'mirrorable'},
    {text: 'esab.cutting.materials.rotatable', value: 'rotatable'},
    {text: 'esab.cutting.materials.rotatableOnly180', value: 'rotatableOnly180'},
    //{text: 'sheet.quantityMinWarning', value: 'quantityMinWarning'},
    //{text: 'sheet.resizable', value: 'resizable'},
  ]

  mounted(): void {
    this.query()
  }

  query(): void {
    this.loading = true
    axiosAuth.get(`api/v1/columbus/sheet`).then((response: AxiosResponse<Sheet[]>) => {
      this.items = response.data
      this.loading = false
    }).catch((error) => {
      console.log(error)
      this.loading = false
    })
  }
}

