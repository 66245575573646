import axios from "axios"

// function getHeaders() {
//   const token = localStorage.getItem('auth.token')
//   if (token) {
//     console.log(token)
//     return {
//       "Content-type": "application/json",
//       "Authorization": "Bearer " + token
//     }
//   } else {
//     return {"Content-type": "application/json"}
//   }
// }

const urlPart = process.env.VUE_APP_TENANT ? process.env.VUE_APP_TENANT + '-' : ''

const backend = process.env.VUE_APP_BACKEND

export const axiosAuth = axios.create({
  baseURL: process.env.NODE_ENV === 'production'
      ? (
          process.env.VUE_APP_RUN_MODE === 'embedded'
              ? '/'
              : `https://gateway-${urlPart}backend.${backend}`
      )
      : 'http://localhost:9090',
  headers: {
    "Content-type": "application/json"
  }
});

axiosAuth.interceptors.request.use(
    config => {
      // const token = store.getState().session.token;
      const token = localStorage.getItem('auth.token')
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
      return config;
    });
