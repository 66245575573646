import jwt_decode from 'jwt-decode';
import {Md5} from 'ts-md5';
import {axiosAuth} from "@/http-common";
import {AxiosError, AxiosResponse} from "axios";
import {Settings} from "@/model/settings";
import {LocaleMessageObject} from "vue-i18n";
import store from "@/store";
import i18n from "@/plugins/i18n";
import {Language} from "@/model/language";
import {State} from "@/assets/machinestates_mixin";

export interface LocalStorageUser {
    token: string
    name: string
    email: string
    avatar: string
    roles: string[]
}

export interface Data {
    expiry: number
}

export function getUserFormToken(token: string): LocalStorageUser {
    console.log('decoding user from token')
    const decoded: any = jwt_decode(token)
    console.log('subject: ' + decoded.sub)
    const decodedHeader: any = jwt_decode(token, {header: true})
    const roles = decodedHeader.roles?.split(',')
    console.log('email: ' + decodedHeader.email)
    console.log('roles: ' + roles)
    const hash = Md5.hashStr(decodedHeader.email.toLowerCase())
    return {
        token: token,
        name: decoded.sub,
        email: decodedHeader.email,
        avatar: 'https://www.gravatar.com/avatar/' + hash,
        roles: roles
    } as LocalStorageUser
}

export function isAdmin(): boolean {
    const user = getUserFromLocalStorage()
    console.log("user role (admin): "+user?.roles)
    if (user?.roles) {
        if (user?.roles.includes("Admin"))
            return true
        if (user?.roles.includes("EdgeAdmin"))
            return true
        if (user?.roles.includes("CutCloudGatewayAdmin"))
            return true
    }
    return false
}


export function isUser(): boolean {
    const user = getUserFromLocalStorage()
    console.log("user role (user): "+user?.roles)
    if (user?.roles) {
        if (user?.roles.includes("User"))
            return true
        if (user?.roles.includes("EdgeUser"))
            return true
        if (user?.roles.includes("CutCloudGatewayUser"))
            return true
    }
    return false
}

export function getTokenFromLocalStorage(): string | null {
    return localStorage.getItem('auth.token');
}

export function getDataFormToken(token: string): Data {
    console.log('decoding data from token')
    const decoded: any = jwt_decode(token)
    return {expiry: decoded.exp} as Data
}

export function getUserFromLocalStorage(): LocalStorageUser | undefined {
    console.log('trying to get auth token from store')
    const token = getTokenFromLocalStorage()
    if (token) {
        return getUserFormToken(token)
    }
    console.log("token not found")
}

function getTokenTimeUntilExpiry(): number | undefined {
    const token = getTokenFromLocalStorage();
    if (token) {
        const tokenData = getDataFormToken(token);
        return (tokenData.expiry * 1000 - Date.now()) / 60000
    } else {
        return undefined
    }
}

export function isTokenExpired(): boolean {
    const timeLeft = getTokenTimeUntilExpiry()
    if (!timeLeft) // no token present
        return false
    return timeLeft <= 0
}

export function startTokenExpiryCheck(interval: number, threshold: number, tokenRefresh: () => void): void {
    console.log('auth -> starting token expiry check')
    setInterval(() => {
        console.log('auth -> checking for token expiry')
        const timeLeft = getTokenTimeUntilExpiry()
        if (timeLeft == undefined) {
            console.log('auth -> no token present')
            return
        }
        if (timeLeft < threshold) {
            console.log('auth -> refreshing token')
            tokenRefresh()
            return
        } else {
            const fmt = timeLeft.toFixed(2)
            console.log(`auth -> ${fmt} minutes remaining until refresh`)
        }
    }, interval * 60000)
}

export function storeUserInLocalStorage(user: LocalStorageUser): void {
    console.log("storing user " + user.name + " in local storage")
    localStorage.setItem('auth.token', user.token)
    localStorage.setItem('auth.user', user.name)
    localStorage.setItem('auth.email', user.email)
    localStorage.setItem('auth.avatar', user.avatar)
    localStorage.setItem('auth.roles', user.roles.join(','))
}

export function clearUserFromLocalStorage(): void {
    localStorage.setItem('auth.token', '')
    localStorage.setItem('auth.user', '')
    localStorage.setItem('auth.email', '')
    localStorage.setItem('auth.avatar', '')
    localStorage.setItem('auth.roles', '')
}


export function updateUiSettings(reloadLanguage: boolean,loggedIn: boolean) {

    const username = localStorage.getItem("auth.user")
    console.log("loading settings and language for user: "+username +"  reload language :" + reloadLanguage)

    axiosAuth.get(`/api/v1/settings/`+username).then((response: AxiosResponse<Settings>) => {
        store.state.settings = response.data
        store.state.moment = require('moment-timezone')
        store.state.moment.locale(store.state.settings.userLanguage);

        if (reloadLanguage)
            updateUiLanguage(store.state.settings.userLanguage,loggedIn)
        return
    }).catch((error: AxiosError) => {
        console.log("Setting:" + error.message)
    })
}

export function updateUiLanguage(languageId: string,loggedIn: boolean) {

    if (languageId == undefined){
        languageId = navigator.language
        languageId = languageId.substring(languageId.length-2,languageId.length).toLowerCase()
    }

    console.log("loading language : " + languageId)

    let url = ""
    if (loggedIn)
        url += "api/v1/common"
    url += "/i18n/load/" + languageId
    axiosAuth.get(url).then((response: AxiosResponse<LocaleMessageObject>) => {
        i18n.setLocaleMessage("database", response.data)
        i18n.locale = 'database'
        store.commit('setLoadingStates', false)
        console.log("loaded language : " + languageId)
        return
    }).catch((error: AxiosError) => {
        console.log("i18n" + error.message)
    })
}

export function getLanguageByLanguageCode(code: string,languages: Language[]): Language {
    let res = languages[0];
    languages.forEach(l => {
        if (!code.localeCompare(l.code) ) {
            res = l
        }
    })
    return res
}

// export function convertUserRollIndexToString(userRollIdx: number): string {
//     if ((userRollIdx & 1) == 1)
//         return 'Admin'
//     if ((userRollIdx & 2) == 2)
//         return 'User'
//     if ((userRollIdx & 4) == 4)
//         return 'Operator'
//     if ((userRollIdx & 64) == 64)
//         return 'Machine'
//     return ''
// }
//
// export function convertUserRollStringToIndex(userRollStr: string): number {
//     if (userRollStr.indexOf("Machine") != -1)
//         return 64
//     if (userRollStr.indexOf("Operator") != -1)
//         return 4
//     if (userRollStr.indexOf("User") != -1)
//         return 2
//     if (userRollStr.indexOf("Admin") != -1)
//         return 3
//     return 0
// }

