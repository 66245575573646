
import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'
import {AxiosResponse} from 'axios'
import {axiosAuth} from '@/http-common'
import {PdmData} from '@/model/pdmdata'
import {decimals, userFormatDate, userFormatTimeBySeconds} from '@/assets/date_utils'
import CurrentProgramPane from '@/components/CurrentProgramPane.vue'
import store from "@/store";

@Component({
  components: {CurrentProgramPane}
})
export default class Events extends Vue {
  @Prop({default: null})
  machineId!: string
  @Prop({default: null})
  groupId!: string

  selectedNcPrg = ''
  showDialog = false

  items: PdmData[] = []
  loading = false

  headers = [
    {
      text: 'esab.cutting.machines.machine',
      value: 'machine.name'
    },
    {text: 'esab.cutting.ncProgram', value: 'ncProgram'},
    {
      text: 'esab.cutting.orders.startDate',
      value: 'startOrder',
      formatter: userFormatDate,
      width: 110
    },
    {
      text: 'esab.cutting.orders.endDate',
      value: 'endOrder',
      formatter: userFormatDate,
      width: 110
    },

    {text: 'esab.cutting.materials.material', value: 'material'},
    {
      text: this.$t('esab.cutting.materials.thickness') + ' ' + '[' + store.state.settings.distUnitName + ']',
      value: 'materialThickness',
      formatter: decimals,
      param: {digits: store.state.settings.distDigits, unitFactor: store.state.settings.distUnitFactor, factor: 1.0}
    },

    {
      text: this.$t('esab.cutting.kerf') + ' ' + '[' + store.state.settings.distUnitName + ']',
      value: 'kerf',
      formatter: decimals,
      param: {digits: store.state.settings.distDigits, unitFactor: store.state.settings.distUnitFactor, factor: 1.0}
    },

    {
      text: this.$t('esab.cutting.pdmData.process1Len') + ' ' + '[' + store.state.settings.distUnitName + ']',
      value: 'processLen1',
      formatter: decimals,
      param: {digits: store.state.settings.distDigits, unitFactor: store.state.settings.distUnitFactor, factor: 1.0}
    },
    {
      text: this.$t('esab.cutting.pdmData.process2Len') + ' ' + '[' + store.state.settings.distUnitName + ']',
      value: 'processLen2',
      formatter: decimals,
      param: {digits: store.state.settings.distDigits, unitFactor: store.state.settings.distUnitFactor, factor: 1.0}
    },
    {
      text: this.$t('esab.cutting.pdmData.process3Len') + ' ' + '[' + store.state.settings.distUnitName + ']',
      value: 'processLen3',
      formatter: decimals,
      param: {digits: store.state.settings.distDigits, unitFactor: store.state.settings.distUnitFactor, factor: 1.0}
    },
    {
      text: this.$t('esab.cutting.pdmData.process4Len') + ' ' + '[' + store.state.settings.distUnitName + ']',
      value: 'processLen4',
      formatter: decimals,
      param: {digits: store.state.settings.distDigits, unitFactor: store.state.settings.distUnitFactor, factor: 1.0}
    },
    {text: this.$t('esab.cutting.pdmData.rapidLen')+ ' ' + '[' + store.state.settings.distUnitName + ']',
      value: 'rapidLen',
      formatter: decimals,
      param: {digits: store.state.settings.distDigits, unitFactor: store.state.settings.distUnitFactor, factor: 1.0}
    },

    {text: 'esab.cutting.pdmData.process1Num', value: 'processNum1'},
    {text: 'esab.cutting.pdmData.process2Num', value: 'processNum2'},
    {text: 'esab.cutting.pdmData.process3Num', value: 'processNum3'},
    {text: 'esab.cutting.pdmData.process4Num', value: 'processNum4'},
    {text: 'esab.cutting.pdmData.rapidNum', value: 'rapidNum'},

    {text: this.$t('esab.cutting.pdmData.process1Time')+ ' ' + '[hh:mm:ss]',
      value: 'processTime1',
      formatter: userFormatTimeBySeconds
    },
    {text: this.$t('esab.cutting.pdmData.process2Time')+ ' ' + '[hh:mm:ss]',
       value: 'processTime2',
      formatter: userFormatTimeBySeconds
    },
    {text: this.$t('esab.cutting.pdmData.process3Time')+ ' ' + '[hh:mm:ss]',
      value: 'processTime3',
      formatter: userFormatTimeBySeconds
    },
    {text: this.$t('esab.cutting.pdmData.process4Time')+ ' ' + '[hh:mm:ss]',
      value: 'processTime4',
      formatter: userFormatTimeBySeconds
    },
    {text: this.$t('esab.cutting.pdmData.rapidTime')+ ' ' + '[hh:mm:ss]',
      value: 'rapidTime',
      formatter: userFormatTimeBySeconds
    },
    {
      text: this.$t('esab.cutting.pdmData.processSpeed') + ' ' + '[' + store.state.settings.distUnitName + '/min]',
      value: 'processSpeed',
      formatter: decimals,
      param: {digits: store.state.settings.distDigits, unitFactor: store.state.settings.distUnitFactor, factor: 1.0}
    },
    {text: this.$t('esab.cutting.pdmData.stops'), value: 'stops'},

    {text: this.$t('esab.cutting.pdmData.errorTime')+ ' ' + '[hh:mm:ss]',
     value: 'errorTime',
      formatter: userFormatTimeBySeconds
    },
    {text: this.$t('esab.cutting.pdmData.unknownTime')+ ' ' + '[hh:mm:ss]',
     value: 'unknownTime',
      formatter: userFormatTimeBySeconds
    },
    {
      text: this.$t('esab.cutting.pdmData.totalTime')+ ' ' + '[hh:mm:ss]',
      value: 'totalTime',
      formatter: userFormatTimeBySeconds
    }
  ]

  mounted(): void {
    this.query()
  }

  query(): void {
    this.loading = true

    let url = `/api/v1/pdmdata`
    if (this.machineId)
      url = `/api/v1/pdmdata/machine/${this.machineId}`
    else if (this.groupId)
      url = `/api/v1/pdmdata/machinegroup/${this.groupId}`

    axiosAuth.get(url).then((response: AxiosResponse<PdmData[]>) => {
      this.items = response.data
      this.loading = false
    }).catch((error) => {
      console.log(error)
    })
  }

  showNcPrg(rec: PdmData): void {
    console.log("NcProgram: "+rec.ncProgram)
    this.selectedNcPrg = rec.ncProgram
    this.showDialog = true
  }

  @Watch('machineId')
  onMachineIdChanged(): void {
    this.query()
  }

  @Watch('groupId')
  onGroupIdChanged(): void {
    this.query()
  }
}
