
import Vue from 'vue'
import {Component} from 'vue-property-decorator'
import {axiosAuth} from '@/http-common'
import {AxiosError, AxiosResponse} from 'axios'
import PageTitle from '@/components/PageTitle.vue'
import BarChart from '@/components/BarChart.vue'
import PieChart from '@/components/PieChart.vue'
import MachineSelection from '@/components/MachineSelection.vue'
import {
  ChartParam,
  ChartView,
  transformEventData,
  transformPdmDataLens,
  transformPdmDataTimes
} from '@/assets/report_utils'

import moment from 'moment'
import GtiDateField from '@/components/GtiDateField.vue'
import {Machine} from '@/model/machine'
import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'
import store from "@/store";

interface ReportQueryParams {
  machineIds: string[]
  machineNames: string[]
  operatorIds: string[]
  operatorNames: string[]
  prepareMode: number
  collectionType: number
  startDate: Date
  startDateActive: boolean
  endDate: Date
  endDateActive: boolean
  prodOrder: string
  prodOrderActive: boolean
  ncProgram: string
  ncProgramActive: boolean
  freeText: string
  freeTextActive: boolean
}

interface DropDownItem {
  text: string
  value: string
  endpointData: string
  endpointPdf: string
  func: any
  param: ChartParam
}

@Component({
  components: {GtiDateField, MachineSelection, PieChart, BarChart, PageTitle, VuePdfApp}
})
export default class Reports extends Vue {

  tab = 0
  tabs = ['esab.general.filter', 'esab.general.chart', 'esab.cutting.documents.pdfDocument', 'esab.cutting.data']
  report: DropDownItem | undefined
  queryParams: ReportQueryParams = {
    machineIds: [],
    operatorIds: [],
    machineNames: [],
    operatorNames: [],
    prepareMode: 0,
    collectionType: 0,
    startDate: moment().subtract(1, 'hours').toDate(),
    startDateActive: false,
    endDate: moment().toDate(),
    endDateActive: false,
    prodOrder: '',
    prodOrderActive: false,
    ncProgram: '',
    ncProgramActive: false,
    freeText: '',
    freeTextActive: false

  }

  charts: ChartView[] = []
  data = {} as any
  documentUrl = ''
  validParameters = false

  created(): void {
    this.report = this.reports[0]
  }

  get reports(): DropDownItem[] {
    return [
      {
        text: this.$t('esab.cutting.reports.reportEvents').toString(),
        value: 'eventtimes',
        endpointData: 'machines/events/times',
        endpointPdf: 'eventtimes',
        func: transformEventData,
        param: {overviewStackbar: true, overviewPie: true, singleMachinePie: true}
      },
      {
        text: this.$t('esab.cutting.reports.reportPdmDataLensSum').toString(),
        value: 'pdmprocesslens_sum',
        endpointData: 'machines/pdm/lens',
        endpointPdf: 'pdmprocesslenssum',
        func: transformPdmDataLens,
        param: {overviewStackbar: true, overviewPie: false}
      },
      {
        text: this.$t('esab.cutting.reports.reportPdmDataLensTotal').toString(),
        value: 'pdmprocesslens',
        endpointData: 'machines/pdm/lens',
        endpointPdf: 'pdmprocesslenstotal',
        func: transformPdmDataLens,
        param: {overviewStackbar: false, overviewPie: true}
      },
      {
        text: this.$t('esab.cutting.reports.reportPdmDataTimeSum').toString(),
        value: 'pdmprocesstimes_sum',
        endpointData: 'machines/pdm/times',
        endpointPdf: 'pdmprocesstimessum',
        func: transformPdmDataTimes,
        param: {overviewStackbar: true, overviewPie: false}
      },
      {
        text: this.$t('esab.cutting.reports.reportPdmDataTimeTotal').toString(),
        value: 'pdmprocesstimes',
        endpointData: 'machines/pdm/times',
        endpointPdf: 'pdmprocesstimestotal',
        func: transformPdmDataTimes,
        param: {overviewStackbar: false, overviewPie: true}
      }
    ]
  }

  resetReport(): void {
    this.tab = 0
    this.resetData()
  }

  resetData(): void {
    this.data = {}
    this.charts = []
    this.documentUrl = ''
  }

  setMachineNames(event: Machine[]): void {
    this.queryParams.machineIds = event.map(m => m.id)
    this.queryParams.machineNames = event.map(m => m.name)
    this.setChange()
  }

  setChange() {
    if (this.queryParams.machineIds.toString().length == 0) {
      this.validParameters = false;
    } else {
      if (this.queryParams.startDate.toString().length < 21 && this.queryParams.startDateActive) {
        this.validParameters = false;
      } else {
        if (this.queryParams.endDate.toString().length < 21 && this.queryParams.endDateActive) {
          this.validParameters = false;
        } else {
          this.validParameters = true
        }
      }
    }
  }


  query(): void {
    let url = `/api/v1/report/${this.report?.endpointData}`
    axiosAuth.post(url, this.queryParams).then((response: AxiosResponse) => {
      this.data = response.data
      this.charts = this.report?.func(this.data, this.report?.param)
      this.pdf()
    }).catch((error: AxiosError) => {
      console.log(error)
    })
  }

  pdf(): void {
    let objJsonStr = JSON.stringify(this.queryParams)
    let enc = btoa(objJsonStr)
    this.documentUrl = ''
    this.documentUrl = Vue.prototype.$backendUrl + `/api/v1/pdf/${this.report?.endpointPdf}/${store.state.settings.userLanguage}/${enc}.pdf`;
  }

  execute(): void {
    this.resetData()
    this.query()
    this.tab = 1
  }
}
