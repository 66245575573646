
import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'
import {AxiosResponse} from 'axios'
import {axiosAuth} from '@/http-common'
import {Event} from '@/model/event'
import {userFormatDate, userFormatTimeBySeconds} from '@/assets/date_utils'
import store from "@/store";

@Component
export default class Events extends Vue {
  @Prop({default: null})
  machineId!: string
  @Prop({default: null})
  groupId!: string

  items: Event[] = []
  loading = false

  headers = [
    {text: 'esab.cutting.machines.machine', value: 'machine.name'},
    {text: 'esab.cutting.ncProgram', value: 'ncProgram'},
    {text: 'esab.cutting.events.beginEvent', value: 'eventStart', formatter: userFormatDate, param: {formatString: store.state.settings.userDateFormat}},
    {text: 'esab.general.info', value: 'eventText'},
    {text: 'esab.cutting.machines.errorGroups.errorGroup', value: 'errorGroup.name'},
    {text: this.$t('esab.cutting.events.eventLen')+' '+'[hh:mm:ss]', value: 'eventLen', formatter: userFormatTimeBySeconds},
    {text: 'esab.cutting.operators.operator', value: 'operator.person.name'}
  ]

  mounted(): void {
    this.query()
  }

  query(): void {
    this.loading = true

    let url = `/api/v1/event`
    if (this.machineId)
      url = `/api/v1/event/machine/${this.machineId}`
    else if (this.groupId)
      url = `/api/v1/event/machinegroup/${this.groupId}`

    axiosAuth.get(url).then((response: AxiosResponse<Event[]>) => {
      this.items = response.data
      this.loading = false
    }).catch((error) => {
      console.log(error)
    })
  }

  @Watch('machineId')
  onMachineIdChanged(): void {
    this.query()
  }

  @Watch('groupId')
  onGroupIdChanged(): void {
    this.query()
  }
}
