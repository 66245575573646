<script>
import {Doughnut} from 'vue-chartjs'

export default {
  extends: Doughnut,
  name: 'PieChart',
  props: {
    chartdata: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      options:
          {
            responsive: true,
            maintainAspectRatio: false,
            pieceLabel: {
              mode: 'percentage',
              // render: function (d) { return d.label + " - " + d.value + " (" + d.percentage + "%)" },
              precision: 1,
              fontColor: '#000',
              position: 'outside',
              segment: true
            }

          }
    }
  },
  mounted() {
    this.renderChart(this.chartdata, this.options)
  },
  mountedAlt() {
    this.renderChart({
      labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
      datasets: [
        {
          backgroundColor: [
            '#41B883',
            '#E46651',
            '#AA08FF',
            '#DD1B16'
          ],
          data: [40, 20, 80, 2],

        }
      ]
    }, {
      responsive: false,
      maintainAspectRatio: false,
      pieceLabel: {
        mode: 'percentage',
        precision: 1,
        fontColor: '#000',
        position: 'outside',
        segment: true
      }
    })
  },
  watch: {
    chartdata() {
      this.renderChart(this.chartdata, this.options)
    }
  }
}
</script>
