import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import VueKonva from 'vue-konva'

import '@/assets/filters'
import '@/plugins/component-registration'

const urlPart = process.env.VUE_APP_TENANT ? process.env.VUE_APP_TENANT + '-' : ''

const backend = process.env.VUE_APP_BACKEND

Vue.prototype.$backendUrl = process.env.NODE_ENV === 'production'
    ? (
        process.env.VUE_APP_RUN_MODE === 'embedded'
            ? ''
            : `https://gateway-${urlPart}backend.${backend}`
    )
    : 'http://localhost:9090'

Vue.use(VueKonva)

Vue.config.productionTip = false

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
