import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export class ValidationMixin extends Vue {

  validationMinChar = 5
  validationMinCharPass = 5
  validationMinCharPassPin = 2

  get rules(): any {
    return {
      required: (v: string) => !!v || this.$t('esab.general.validation.required').toString(),
      min: (v: string) => v.length >= this.validationMinChar || this.$t('esab.general.validation.minChar')+' '+this.validationMinCharPass.toString(),
    }
  }

  get passRules(): any {
    return {
      required: (v: string) => !!v || this.$t('esab.general.validation.required').toString(),
      min: (v: string) => v.length >= this.validationMinCharPass || this.$t('esab.general.validation.minChar')+' '+this.validationMinCharPass.toString(),
    }
  }

  get passPinRules(): any {
    return {
      required: (v: string) => !!v || this.$t('esab.general.validation.required').toString(),
      min: (v: string) => v.length >= this.validationMinCharPassPin || this.$t('esab.general.validation.minChar')+' '+this.validationMinCharPassPin.toString(),    }
  }

  get passEditRules(): any {
    return {
      required: (v: string) => (!!v || v.length < 1) || this.$t('esab.general.validation.required').toString(),
      min: (v: string) => (v.length >= this.validationMinCharPass || v.length < 1 ) || this.$t('esab.general.validation.minChar')+' '+this.validationMinCharPass.toString(),
    }
  }

  get passPinEditRules(): any {
    return {
      required: (v: string) => (!!v || v.length < 1) || this.$t('esab.general.validation.required').toString(),
      min: (v: string) => (v.length >= this.validationMinCharPassPin || v.length < 1 ) || this.$t('esab.general.validation.minChar')+' '+this.validationMinCharPassPin.toString(),
    }
  }

  get emailRules(): any {
    return [
      //(v: string) => (!!v || (v!=null && v.length <1)) || this.$t('validation.emailRequired').toString(),
      (v: string) => ((v!=null && v.length <1) || (/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,10})+$/.test(v))) || this.$t('esab.general.validation.invalidEmail').toString()
    ]
  }

}
