
import {Component} from 'vue-property-decorator'
import Vue from 'vue'
import PageTitle from '@/components/PageTitle.vue'
import InfoLine from '@/components/InfoLine.vue'

@Component({
  components: {InfoLine, PageTitle}
})
export default class Contact extends Vue {
}

